import React, { useState } from 'react';
import { 
    activities_path,
    root_path, 
    new_user_session_path,
    how_it_works_path
} from 'routes';
import * as styles from '@scss/navbar.mod';
import Logo from '@images/logo.png';
import Modal from '@components/Modal';
import RegisterForm from '@components/RegisterForm';
import { classes } from '@root/helpers';

const Navbar = (props) => {
    const { user } = props;
    const [modalOpen, setModalOpen] = useState(false);
    const active = (path) => location.pathname === path && styles.active;

    return (
        <div className={styles.nav}>
            <div className={classes('pure-menu', 'pure-menu-horizontal', styles.navWrapper)}>
                <div className={styles.navContainer}>
                    <div className={styles.leftNav}>
                        <a onClick={() => window.location.href = user ? activities_path() : root_path()}>
                            <img src={Logo} className={classes('pure-img', styles.logo)} alt="logo" />
                        </a>
                    </div>
                    <div className={styles.rightNav}>
                        <ul className="pure-menu-list">
                            <li className="pure-menu-item">
                                <a onClick={() => window.location.href = user ? activities_path() : root_path()} className={classes('pure-menu-link', styles.navLink, active(root_path()))}>Home</a>
                            </li>
                            <li className="pure-menu-item">
                                <a href={how_it_works_path()} className={classes('pure-menu-link', styles.navLink, active(how_it_works_path()))}>How it works</a>
                            </li>
                            <li className="pure-menu-item">
                                <a onClick={() => setModalOpen(true)} className={classes('pure-menu-link', styles.navLink)}>Request access</a>
                            </li>
                            { !user && 
                                <li className="pure-menu-item">
                                        <a 
                                            href={new_user_session_path()} 
                                            className={classes('pure-button', 'button-secondary', styles.loginBtn)}
                                        >
                                            Log In
                                        </a>
                                </li>
                            }
                        </ul>
                    </div>
                </div>
            </div>
            <Modal visible={modalOpen} onClose={() => setModalOpen(false)}>
                <RegisterForm />
            </Modal>
        </div>
    );
};

export default Navbar;