import React from 'react'
import { withJsonFormsControlProps } from '@jsonforms/react'
import Checkbox from './Checkbox';

const CheckboxControl = (props) => {
    const {
        id,
        data,
        label,
        handleChange,
        path,
        title,
    } = props;

    return (
        <Checkbox
            id={id}
            label={label}
            data={data}
            updateValue={(newValue) => handleChange(path, newValue)}
        />
    );
};

export default withJsonFormsControlProps(CheckboxControl);
