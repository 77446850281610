import React, { useState } from 'react';
import _lo from 'lodash';
import { root_path } from 'routes';
import styles from '@scss/authorize.mod';

const Authorize = (props) => (
  <div className={styles.authorizeWrapper}>
    <h3>Unauthorized Access</h3>  
    <a className="pure-button button-primary" href={root_path()}>Return to Homepage</a>
  </div>
);

export default Authorize;

