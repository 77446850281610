import React from 'react';
import styles from '@scss/banner.mod';
import { classes } from '@root/helpers';

const Banner = ({ leftImg, heading, subtitle, paragraph, rightImg, reverse }) => (
    <div className={classes(styles.banner, reverse && styles.reverse)}>
        <img className={styles.leftImage} src={leftImg} alt="Pic" />
        <div className={styles.textWrapper}>
            { subtitle && <p className={styles.subtitle}> {subtitle} </p> }
            <h1 className={styles.heading}>
                { heading }
            </h1>
            <p className={styles.paragraph}>
                { paragraph }
            </p>
        </div>
        <img className={styles.rightImage} src={rightImg} alt="title" />
    </div>
);

export default Banner;