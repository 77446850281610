import React from 'react';
import { createMuiTheme, ThemeProvider } from "@material-ui/core";

const theme = createMuiTheme({
    palette: {
        primary: {
            light: '#484848',
            main: '#212121',
            dark: '#000000',
            contrastText: '#fff'
        }
    }
});

export const withOurTheme = (Component) => (props) => <ThemeProvider theme={theme}>
    <Component {...props} />
</ThemeProvider>

export default theme;