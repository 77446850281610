import {
    rankWith,
    isStringControl,
    scopeEndsWith,
} from '@jsonforms/core';

// export default rankWith(
//     3, and(isStringControl, not(isEnumControl))
// );

export default rankWith(
    3, scopeEndsWith('Input')
);
