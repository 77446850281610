import React, { useEffect, useRef } from 'react';
import * as styles from '@scss/loader.mod';
import LoaderGif from '@images/Loader.gif';
import { classes } from '@root/helpers';

const Loader = ({ active }) => (
  <div className={classes(styles.loader, active && styles.visible)}>
    <img className={styles.image} src={LoaderGif} alt="Loading" />
    <div className={styles.loading}>
      <span>LOADING</span>
    </div>
  </div>
);

export default Loader;
