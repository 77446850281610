import React from 'react';
import { useMediaQuery } from 'react-responsive';
import * as styles from '@scss/templateExample.mod';
import TemplatePC from '@images/templatePc.svg';
import Close from '@images/close-button.svg';
import { classes } from '@root/helpers';

const ToolExample = ({ details, toolOpen, setToolOpen }) => {
    const isTabletOrMobile = useMediaQuery({ maxWidth: 1023 });
    const isOpen = isTabletOrMobile && toolOpen;
    const available = true;

    return (
        <div className={classes(styles.exampleWrapper, isOpen && styles.displaying)}>
            <div className={styles.exampleContainer}>
                <div className={styles.header}>
                    <h6>Tool Preview</h6>
                    {
                        isTabletOrMobile && 
                            <img src={Close} className={classes('pure-img', styles.tempImg)} alt="TemplatePC" onClick={() => setToolOpen(false)}/>
                    }
                </div>
                <div className={styles.innerExample}>
                    <div className={styles.imageWrapper}>
                        <img src={TemplatePC} className={classes('pure-img', styles.tempImg)} alt="TemplatePC" />
                    </div>
                    <h6>{details.name}</h6>
                    <div className={styles.description}>
                        <h6>Description</h6>
                        <p className={styles.desc}> 
                            {details.description}
                        </p>
                    </div>
                    <a className={classes('button-primary', styles.tmpBtn, styles.use, !available && styles.disable)} href={details.link}>{available ? 'Use this tool' : 'Not available'}</a>
                </div>
            </div>
        </div>
    );
}

export default ToolExample;
