const defaultSchema = {
    "type": "object",
    "description": "This is an activity that displays a countdown timer under the GNB along with a discount messaging. It has been rolled out successfully across different markets, and its best suited for flash sales or activities that are supposed to run for a limited/short period of time.",
    "definitions": {
        "Page": {
            "title": "Targeted Page",
            "type": "object",
            "properties": {
                "page": {
                    "title": "Page URL(s)",
                    "description": "Enter full page URL",
                    "oneOf": [
                        {
                            "type": "string",
                            "title": "Page URL",
                            "minLength": 4
                        },
                        {
                            "type": "array",
                            "title": "Page URL(s)",
                            "minItems": 1,
                            "items": {
                                "type": "string"
                            }
                        }
                    ]
                },
                "link_page": {
                    "title": "Banner Link",
                    "description": "This is the URL that the user is navigated to when clicking on the banner / CTA. Leave empty if N/A",
                    "type": [
                        "string",
                        "null"
                    ]
                },
                "dontMiss": {
                    "title": "Left Copy",
                    "description": "Shown on the left of the banner",
                    "type": "string"
                },
                "header": {
                    "title": "Right copy",
                    "type": "string"
                },
                "terms": {
                    "title": "Terms",
                    "description": "Added to the footer",
                    "type": "string"
                },
                "arrow": {
                    "title": "Show arrow",
                    "type": "boolean",
                    "default": false
                },
                "fullWidth": {
                    "title": "Full width",
                    "type": "boolean",
                    "default": false
                },
                "slimDesign": {
                    "title": "Slim design",
                    "type": "boolean",
                    "default": false
                },
                "start_date": {
                    "title": "Start Date / Time",
                    "examples": [
                        "2021-06-03T00:01"
                    ],
                    "type": "string",
                    "format": "date-time",
                    "options": {
                        "dateTimeFormat": "YY",
                        "dateTimeSaveFormat": "YY",
                        "ampm": true
                    }
                },
                "end_date": {
                    "title": "End Date / Time",
                    "examples": [
                        "2021-06-06T00:01"
                    ],
                    "type": "string",
                    "format": "date-time",
                    "options": {
                        "dateTimeFormat": "YY",
                        "dateTimeSaveFormat": "YY",
                        "ampm": true
                    }
                }
            },
            "required": [
                "page",
                "dontMiss",
                "header",
                "start_date",
                "end_date"
            ]
        }
    },
    "properties": {
        "lastChance": {
            "title": "Left Copy (Last day)",
            "description": "Only shown on the last day of the offer. Leave empty if N/A",
            "optional": true,
            "type": "string"
        },
        "shopNow": {
            "title": "Shop now CTA",
            "default": "Shop now",
            "type": "string"
        },
        "days": {
            "title": "'Days' translation",
            "type": "string",
            "description": "'Days' translation"
        },
        "hrs": {
            "title": "'Hrs' translation",
            "type": "string",
            "description": "'Hrs' translation"
        },
        "mins": {
            "title": "'Mins' translation",
            "type": "string",
            "description": "'Mins' translation"
        },
        "sec": {
            "type": "string",
            "title": "'Sec' translation",
            "description": "'Secs' translation"
        },
        "pages": {
            "title": "Targeted Pages",
            "type": "array",
            "minItems": 1,
            "items": {
                "$ref": "#/definitions/Page"
            }
        }
    },
    "required": [
        "pages"
    ]
};

const defaultUiSchema = {
    "type": "VerticalLayout",
    "elements": [
        {
            "type": "VerticalLayout",
            "elements": [
                {
                    "type": "Control",
                    "scope": "#/properties/lastChance"
                },
                {
                    "type": "Control",
                    "scope": "#/properties/shopNow"
                },
                {
                    "type": "Control",
                    "scope": "#/properties/days"
                },
                {
                    "type": "Control",
                    "scope": "#/properties/hrs"
                },
                {
                    "type": "Control",
                    "scope": "#/properties/mins"
                },
                {
                    "type": "Control",
                    "scope": "#/properties/sec"
                }
            ]
        },
        {
            "type": "HorizontalLayout",
            "elements": [
                {
                    "type": "Control",
                    "scope": "#/properties/pages"
                }
            ]
        }
    ]
};

export {
    defaultSchema,
    defaultUiSchema
};