import React, { useState, useRef, useCallback } from 'react';
import * as styles from '@scss/filter.mod';
import UserIcon from '@images/user.svg';
import UsersIcon from '@images/users.svg';
import Arrow from '@images/arrow.svg';
import { classes, useOnClickOutside } from '@root/helpers';

const Filter = (props) => {
    const { current_user, conditionParam, setConditionParam, slcMarket, setSlcMarket, firstOptions, secondOptions } = props
    const [ firstdropdown, setFirstDropdown ] = useState(false);
    const [ secondDropdown, setSecondDropdown] = useState(false);
    const firstFilter = useRef();
    const secondFilter = useRef();
    useOnClickOutside(firstFilter, useCallback(() => setFirstDropdown(false), []));
    useOnClickOutside(secondFilter, useCallback(() => setSecondDropdown(false), []));

    const changeMarket = (selectedOption) => {
        sessionStorage.setItem("selectedMarket", selectedOption);
        setSlcMarket(selectedOption);
    }

    return (
        <div className={styles.filterContainer}>
            <div className={styles.firstFilterBtn} >
                <div className={styles.fisrstFilterBtnContent} onClick={() => setFirstDropdown(true)}>
                    {
                        conditionParam ? 
                            <img src={UsersIcon} className={classes('pure-img', styles.userImg)} alt="Users" />
                            :
                            <img src={UserIcon} className={classes('pure-img', styles.userImg)} alt="User" />
                    }
                    <img src={Arrow} className={classes('pure-img', styles.arrow)} alt="Arrow" />
                </div>
                <ul className={classes('pure-menu-list', !firstdropdown && styles.hide)} ref={firstFilter}>
                    <li className="pure-menu-item">
                        {
                            conditionParam ?
                                <div className={styles.listItem}>
                                    <img src={UserIcon} className={classes('pure-img', styles.userImg)} alt="User" />
                                    <a className={`pure-menu-link ${styles.link}`} onClick={() => changeMarket(current_user.market)}>
                                        {firstOptions[0]}
                                    </a>
                                </div>
                                :
                                <div className={styles.listItem}>
                                    <img src={UsersIcon} className={classes('pure-img', styles.userImg)} alt="Users" />
                                    <span className={`pure-menu-link ${styles.link}`} onClick={() => setConditionParam(true)}>
                                        {firstOptions[1]}
                                    </span>
                                </div>
                        }
                    </li>
                </ul>
            </div>
            {
                conditionParam ?
                    <h6>{firstOptions[1]}</h6>
                    :
                    <h6>{firstOptions[0]}</h6>
            }
            <div className={classes(styles.secondFilterBtn, !conditionParam && styles.hide)}>
                <div className={styles.secondFilterBtnContent} onClick={() => setSecondDropdown(true)}>
                    <p className={styles.parameter}>Market:</p>
                    <p className={styles.parameterName}>{(slcMarket && slcMarket != 'null') ? slcMarket : current_user.market}</p>
                    <img src={Arrow} className={classes('pure-img', styles.arrow)} alt="Arrow" />
                </div>
                <ul className={classes('pure-menu-list', !secondDropdown && styles.hide)} ref={secondFilter}>
                    <li className="pure-menu-item">
                        {
                            secondOptions.map((option) => (
                                <a 
                                    className={classes('pure-menu-link', styles.link, option == slcMarket && styles.selected)} 
                                    onClick={() => changeMarket(option)} 
                                    key={option}
                                >
                                    {option}
                                </a>
                            ))
                        }
                    </li>
                </ul>
            </div>
        </div>
    );
};

export default Filter;
