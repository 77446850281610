import React from 'react';
import { how_it_works_path, root_path, faq_path, privacy_policy_path } from 'routes';
import * as styles from '@scss/footer.mod';
import SogodyLogo from '@images/sogodyLogo.png';
import FacebookLogo from '@images/facebook.svg';
import InstagramLogo from '@images/instagram.svg';
import LinkedinLogo from '@images/linkedin.svg';
import TwitterLogo from '@images/twitter.svg';
import DiagonalArrow from '@images/diagonal-arrow.svg';
import { classes, outsidePage } from '../helpers';

const Footer = (props) => {
    const { user } = props;
    const pageList = [/faq/, /how_it_works/];
    if (user && outsidePage(pageList) === false) return <></>;
    return (
        <div className={styles.footerWrapper}>
            <div className={styles.footerContainer}>
                <div className={styles.footerTop}>
                    <div className={styles.footerColumn}>
                        <p>Company</p>
                        <a href="https://sogody.com/" target="_blank" className={styles.footerLink}>
                            About Sogody 
                            <img src={DiagonalArrow} className={classes('pure-img', styles.arrow)} alt="diagonal-arrow" />
                        </a>
                        <a href="https://sogody.com/" target="_blank" className={styles.footerLink}>
                            Contact us 
                            <img src={DiagonalArrow} className={classes('pure-img', styles.arrow)} alt="diagonal-arrow" />
                        </a>
                    </div>
                    <div className={styles.footerColumn}>
                        <p>Useful links</p>
                        <a href={how_it_works_path()} className={styles.footerLink}>How it works</a>
                        <a href={faq_path()} className={styles.footerLink}>FAQs</a>
                    </div>
                    <div className={styles.footerColumn}>
                        <p>Resources</p>
                        <a href="#" className={styles.footerLink}>What's new</a>
                        <a href="#" className={styles.footerLink}>Cookies Policy</a>
                    </div>
                    <div className={styles.footerColumn}>
                        <p>Legal</p>
                        <a href="#" className={styles.footerLink}>Terms of services</a>
                        <a href={privacy_policy_path()} className={styles.footerLink}>Privacy Policy</a>
                    </div>
                </div>

                <div className={classes('pure-g', styles.copyright)}>
                    <div className='pure-u-1 pure-u-sm-1-4'>
                        <a href={root_path()}>
                            <img src={SogodyLogo} className={classes('pure-img', styles.logo)} alt="logo" />
                        </a>
                        <div className={classes(styles.footerLink, styles.copy)}>© 2021</div>
                    </div>
                    <div className='pure-u-1 pure-u-sm-3-4'>
                        <a href='https://twitter.com/sogodycom' target="_blank">
                            <img src={TwitterLogo} className={classes('pure-img', styles.social)} alt="twitter" />
                        </a>
                        <a href='https://fj.linkedin.com/company/sogody' target="_blank">
                            <img src={LinkedinLogo} className={classes('pure-img', styles.social)} alt="linkedin" />
                        </a>
                        <a href='https://www.instagram.com/sogodycom/' target="_blank">
                            <img src={InstagramLogo} className={classes('pure-img', styles.social)} alt="instagram" />
                        </a>
                        <a href='https://www.facebook.com/sogodycom/' target="_blank">
                            <img src={FacebookLogo} className={classes('pure-img', styles.social)} alt="facebook" />
                        </a>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Footer;