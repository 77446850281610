import React, { useState } from 'react';
import { useMediaQuery } from 'react-responsive';
import * as styles from '@scss/boxList.mod';
import Trademark from '@components/Trademark';
import ToolExample from '@components/Tool/ToolExample';
import Box from '@components/Box/Box';
import tools from '@schemas/Tools';

const ToolList = () => {
    const [ details, setDetails ] = useState({ name: tools[0]?.name, description: tools[0]?.description, link: tools[0]?.link });
    const [ _ , setTool] = useState(tools[0]);

    const [toolOpen, setToolOpen] = useState(false);
    const isDesktopOrLaptop = useMediaQuery({ minWidth: 1024 });
    const isTabletOrMobile = useMediaQuery({ maxWidth: 1023 });

    return (
        <div className={styles.templateWrapper}>
            <div className={styles.templateContainer}>
                <div className={styles.header}>
                    <h3>Tools</h3>
                </div>
                <div className={styles.filter}>
                </div>
                <div className={styles.template}>
                    {
                        tools.map((tool) => (
                            <Box
                                key={tool.name} 
                                content={tool}
                                setContent={setTool}
                                setDetails={setDetails}
                                setContentOpen={setToolOpen}
                                isDesktopOrLaptop={isDesktopOrLaptop}
                            />
                        ))
                    }
                </div>
                <Trademark />
            </div>
            <div className={styles.template}>
                <ToolExample 
                    details={details} 
                    toolOpen={toolOpen}
                    setToolOpen={setToolOpen} 
                /> 
                <div className={styles.placement} />
            </div>
            { isTabletOrMobile && <div className={styles.mobilePlacement} /> }
        </div>
    );
}

export default ToolList;