import React, { useEffect, useRef, useState } from 'react';
import { 
    activities_path,
    root_path, 
    new_user_session_path,
    how_it_works_path
} from 'routes';
import * as styles from '@scss/menu.mod';
import { classes } from '@root/helpers';

const Menu = ({visible, setModalOpen, user}) => {
    const _menu = useRef(null);

    useEffect(() => {
        if (visible) {
            _menu.current.classList.add(styles.displaying); 
            setTimeout(() => _menu.current?.classList.add(styles.visible), 200);
        } else {
            _menu.current.classList.remove(styles.visible);
            setTimeout(() => _menu.current?.classList.remove(styles.displaying), 200);
        }
    });
    return (
        <div ref={_menu} className={styles.mobileNav}>
            <div className={styles.list}>
                <ul className="pure-menu-list">
                    <li className="pure-menu-item">
                        <a onClick={() => window.location.href = user ? activities_path() : root_path()} className={classes('pure-menu-link', styles.navLink)}>
                            Home
                        </a>
                    </li>
                    <li className="pure-menu-item">
                        <a href={how_it_works_path()} className={classes('pure-menu-link', styles.navLink)}>
                            How it works
                        </a>
                    </li>
                    <li className="pure-menu-item">
                        <a onClick={() => setModalOpen(true)} className={classes('pure-menu-link', styles.navLink)}>
                            Request access
                        </a>
                    </li>
                </ul>
            </div>
            { !user && 
                <div className={styles.log}>
                    <p className={styles.logTxt}>Log in to see your dashboard</p>
                    <a 
                        href={new_user_session_path()} 
                        className={classes('pure-button', 'button-secondary', styles.loginBtn)}
                    >
                        Log In
                    </a>
                </div>
            }
        </div>
    );
};

export default Menu;