import axios from 'axios';
import React, { useRef, useState, useCallback } from 'react';
import {
    edit_activity_path,
    at_config_activity_path,
    clone_activity_path,
    tokens_activity_path,
} from 'routes';
import * as styles from '@scss/activityRow.mod';
import Points from '@images/ellipsisw.svg';
import Pen from '@images/pen.svg';
import Built from '@images/built.svg';
import Nike from '@images/nike.svg';
import Failed from '@images/failed.svg';
import Clone from '@images/clone.svg';
import { getIitials, useOnClickOutside, classes, sendExtMessage } from '@root/helpers';

const ActivityRow = (props) => {
    const [activity, setActivity] = useState(props.activity);
    const current_user = props.current_user
    const [disabled, setDisabled] = useState(false);
    const [deployDisabled, setDeployDisabled] = useState(false);
    const [syncDisabled, setSyncDisabled] = useState(false);
    const [dropdown, setDropdown] = useState(false);
    const ref = useRef();

    const hasAccess = current_user.role !== "user" || current_user.id === activity.user_id;

    useOnClickOutside(ref, useCallback(() => setDropdown(false), []));

    const handleSync = () => {
        setSyncDisabled(true);

        axios.get(at_config_activity_path(activity.id)).then((response) => {
            const { target_env, activity_url } = response.data;

            sendExtMessage(
                {
                    greeting: "SyncActivity",
                    targetUrl: `https://experience.adobe.com/#/@${target_env.subdomain}/target/activities`,
                    payload: JSON.stringify({}),
                    groupId: target_env.user_group_id,
                    deploymentUrl: activity_url,
                    apiUrl: `https://${target_env.subdomain}.experiencecloud.adobe.com/content/mac/${target_env.subdomain}/target/activities/${activity.remote_id}.at.json`
                },
                (response) => {
                    if (!response.farewell) return;
                }
            );
        }).finally(() => setSyncDisabled(false));
    }

    const handleDeploy = () => {
        setDeployDisabled(true);
        axios.post(at_config_activity_path(activity.id)).then((response) => {
            const { target_env, model, deployment_url } = response.data;

            sendExtMessage(
                {
                    greeting: "BuildActivity",
                    targetUrl: `https://experience.adobe.com/#/@${target_env.subdomain}/target/activities`,
                    payload: JSON.stringify(model),
                    groupId: target_env.user_group_id,
                    deploymentUrl: deployment_url,
                    apiUrl: `https://${target_env.subdomain}.experiencecloud.adobe.com/content/mac/${target_env.subdomain}/target/activities.at.json`
                },
                (response) => {
                    if (!response.farewell) return;
                }
            );
        }).finally(() => setDeployDisabled(false));
    }

    const inDevelopment = () => {
        const host = window.location.host;
        return host.match('localhost|127.0.0.1');
    }

    const genPreviewLinks = (editable) => {
        axios.get(tokens_activity_path(activity.id))
            .then((response) => {
                if (response.data.error) return;

                const variations = response.data.map((variation) => {
                    const token = variation.token.toString();
                    const devToken = inDevelopment() ? '&sgd-dev=true' : '';
                    const editableToken = '&sgd-editable=true';

                    return {
                        base: `?sgd-preview-token=${token}`,
                        name: variation.experience_name,
                        source: variation.file_name,
                        dev: devToken,
                        editable: editableToken,
                    };
                });

                props.setOpenDialog(true);
                props.setDialogContent(null);
                props.setDialogVariations(variations);
            });
    }

    const genClipboardLinks = () => {
        axios.get(tokens_activity_path(activity.id))
            .then((response) => {
                if (response.data.error) return;

                const variations = response.data.map((variation) => {
                    const token = variation.token.toString();

                    return {
                        token,
                        name: variation.experience_name,
                    };
                });

                props.setOpenDialog(true);
                props.setDialogContent('clipboard');
                props.setDialogVariations(variations);
            });
    }

    const isAvailable = () => hasAccess && activity.config;
    const isConfigured = () => !disabled && isAvailable();
    const syncAvailable = () => !syncDisabled && isAvailable();

    return (
        <tr className={styles.activityRow}>
            <td>
                {
                    !isConfigured() && activity.last_deployment_status !== "failed"
                    &&
                    <a className={styles.status}>
                        <span className={classes(styles.circle, styles.created)}>
                            <img src={Pen} className={classes('pure-img', styles.icon)} alt="Pen" />
                        </span>
                        Created
                    </a>
                }
                {
                    isConfigured() && activity.last_deployment_status !== "failed"
                    &&
                    <a className={styles.status}>
                        <span className={classes(styles.circle, styles.built)}>
                            <img src={Built} className={classes('pure-img', styles.icon)} alt="Built" />
                        </span>
                        Ready
                    </a>
                }
                {
                    !activity.last_deployment_status === "succedded"
                    &&
                    <a className={styles.status}>
                        <span className={classes(styles.circle, styles.deployed)}>
                            <img src={Nike} className={classes('pure-img', styles.icon)} alt="Nike" />
                        </span>
                        Deployed
                    </a>
                }
                {
                    activity.last_deployment_status === "failed"
                    &&
                    <a className={styles.status}>
                        <span className={classes(styles.circle, styles.failed)}>
                            <img src={Failed} className={classes('pure-img', styles.icon)} alt="Failed" />
                        </span>
                        Failed
                    </a>
                }
            </td>
            <td><p className={styles.market}>{activity.market}</p></td>
            <td><p className={styles.name}>{activity.name}</p></td>
            <td><p className={styles.template}>{activity.template}</p></td>
            <td><p className={styles.normal}>
                {
                    new Date(activity.updated_at).toLocaleString()
                }
            </p>
            </td>
            <td>
                <div className={styles.author}>
                    <span className={styles.usr}>{getIitials(activity.get_activity_author)}</span>
                    <p className={styles.normal}>{activity.get_activity_author}</p>
                </div>
            </td>
            <td>
                {
                    hasAccess ?
                        (
                            !isConfigured() ?
                                <a className={classes('btn-secondary', styles.secBtn)} href={edit_activity_path({ id: activity.id })}>
                                    Configure
                                </a> :
                                <a className={classes('btn-primary', styles.dplBtn)} onClick={handleDeploy} >
                                    Deploy
                                </a>
                        )
                        :
                        <div className={styles.options}>
                            <a className={classes('button-ternary', (hasAccess || !isConfigured()) && styles.hide, styles.access)} onClick={genPreviewLinks}>VIEW</a>
                            <a className={styles.clone} href={clone_activity_path(activity.id)}>
                                <img src={Clone} className={classes('pure-img', hasAccess && styles.hide, styles.access)} alt="Clone" />
                            </a>
                        </div>
                }
            </td>
            <td onClick={() => setDropdown(true)}>
                <div className={styles.drop}>
                    <img src={Points} className={classes('pure-img', styles.points)} alt="Points" />
                    <ul className={classes('pure-menu-list', !dropdown && styles.hide)} ref={ref}>
                        <li className="pure-menu-item">
                            <a className={classes('pure-menu-link', styles.link)} href={edit_activity_path({ id: activity.id })}>
                                Edit
                            </a>
                        </li>
                        <li className={classes('pure-menu-item', !hasAccess && styles.hide)}>
                            <a className={classes('pure-menu-link', styles.link)} href={clone_activity_path(activity.id)}>
                                Clone
                            </a>
                        </li>
                        <li className="pure-menu-item">
                            <a className={classes('pure-menu-link', styles.link, !isConfigured() && styles.disabledLink)} onClick={handleDeploy}>
                                Deploy
                            </a>
                        </li>
                        <li className="pure-menu-item">
                            <a className={classes('pure-menu-link', styles.link, !syncAvailable() && styles.disabledLink)} onClick={handleSync}>
                                Sync from Target
                            </a>
                        </li>
                        <li className={classes('pure-menu-item', !hasAccess && styles.hide)}>
                            <a className={classes('pure-menu-link', styles.link, !isConfigured() && styles.disabledLink)} onClick={genPreviewLinks}>
                                Generate Preview Link
                            </a>
                        </li>
                        <li className="pure-menu-item">
                            <a className={classes('pure-menu-link', styles.link, !isConfigured() && styles.disabledLink)} onClick={genClipboardLinks}>
                                Copy code to Clipboard
                            </a>
                        </li>
                        <li className="pure-menu-item">
                            <a className={classes('pure-menu-link', styles.link, styles.disabledLink)}>Download config</a>
                        </li>
                        <li className="pure-menu-item">
                            <a className={classes('pure-menu-link', styles.link, styles.disabledLink)}>Download source</a>
                        </li>
                    </ul>
                </div>
            </td>
            <td>
                <div className={styles.tdMobile}>
                    <div className={styles.tdData}>
                        <p className={styles.market}>{activity.market}</p>
                        <p className={styles.name}>{activity.name}</p>
                        <p className={styles.template}>{activity.template}</p>
                        <p className={styles.normal}>
                            Last updated on &nbsp;
                            {
                                new Date(activity.updated_at).toLocaleString()
                            }
                        </p>
                    </div>
                    <span className={styles.usr}>{getIitials(activity.get_activity_author)}</span>
                </div>
            </td>
        </tr>
    )
}

export default ActivityRow;
