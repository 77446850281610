import React from 'react'
import styles from '@scss/privacyPolicy.mod'
import Banner from '@components/Banner'
import left from '@images/LeftFaq.png';
import right from '@images/RightFaq.png';

const PrivacyPolicy = () => {
    return (
        <React.Fragment>
            <Banner 
                leftImg={left} 
                rightImg={right} 
                subtitle='Template' 
                heading='Privacy Policy' 
                paragraph='Effective starting: October 31, 2020'
                reverse={true}
            />
            <div className={styles.container}>
                <div className={styles.textWrapper}>
                    <h3>What this policy covers</h3>
                    <p>
                        This Privacy Policy covers the information we collect about you when you
                        use our products or services, or otherwise interact with us (for
                        example, by attending our premises or events or by communicating with
                        us), unless a different policy is displayed. Atlassian, we and us refers
                        to Atlassian Pty Ltd, Atlassian, Inc. and any of our corporate
                        affiliates. We offer a wide range of products, including our cloud,
                        server and data center products. We refer to all of these products,
                        together with our other services and websites as “Services” in this
                        policy.
                    </p>
                    <p>
                        This policy also explains your choices surrounding how we use
                        information about you, which include how you can object to certain uses
                        of information about you and how you can access and update certain
                        information about you. <span> If you do not agree with this policy, do not access or use our Services or interact with any other aspect of our business.</span>
                    </p>
                    <p>
                        Where we provide the Services under contract with an organization (for
                        example, your employer) that organization controls the information
                        processed by the Services. For more information, please see Notice to
                        End Users below. This policy does not apply to the extent we process
                        personal information in the role of a processor on behalf of such
                        organizations.
                    </p>
                </div>
            </div>
        </React.Fragment>
    );
};

export default PrivacyPolicy;
