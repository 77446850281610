import React from 'react'
import { withJsonFormsControlProps } from '@jsonforms/react'
import Input from './Input';

const InputControl = (props) => {
    const {
        id,
        label,
        data,
        handleChange,
        path,
        title,
        description,
        required
    } = props;

    return (
        <React.Fragment>
            <Input
                id={id}
                title={title}
                label={label}
                data={data}
                required={required}
                updateValue={(newValue) => handleChange(path, newValue)}
            />
        </React.Fragment>
    );
};

export default withJsonFormsControlProps(InputControl);
