import React, { useState, useEffect } from 'react';
import { useMediaQuery } from 'react-responsive';
import Sidebar from '@components/Navigation/Sidebar';
import Navbar from '@components/Navigation/Navbar';
import MobileNavbar from '@components/Navigation/MobileNavbar';
import MobileTopSidebar from '@components/Navigation/MobileTopSidebar';
import MobileBottomSidebar from '@components/Navigation/MobileBottomSidebar';
import Modal from '@components/Modal';
import NoticeBanner from '@components/NoticeBanner';
import { outsidePage } from '@root/helpers';

const Navigation = (props) => {
    const { user } = props;
    const [noticeBanner, setNoticeBannerOpen] = useState(false);
    const isDesktopOrLaptop = useMediaQuery({ minWidth: 1024 });
    const pageList = [/faq/, /how_it_works/];

    const closeNoticeBanner = () => {
        sessionStorage.setItem("testfulNotice", (new Date()).toISOString());
        setNoticeBannerOpen(false);
    };

    const DesktopMenu = () => {
        if (user && outsidePage(pageList) === false) return <Sidebar user={user}/>;
        return <Navbar user={user}/>;
    };

    const MobileMenu = () => {
        if (user && outsidePage(pageList) === false) return (
            <>
                <MobileTopSidebar />
                <MobileBottomSidebar />
            </>
        );
        return <MobileNavbar user={user}/>;
    }

    const Menu = () => {
        if (isDesktopOrLaptop) return <DesktopMenu />;
        return <MobileMenu />
    }
    
    return (
        <>
            <Menu />  
            {
                user && 
                (
                    <Modal visible={noticeBanner} onClose={closeNoticeBanner}>
                        <NoticeBanner onClose={closeNoticeBanner} setOpen={setNoticeBannerOpen}></NoticeBanner>
                    </Modal>
                )
            }
        </>
    );
};

export default Navigation;