import React, { useEffect } from 'react';
import { Button } from '@material-ui/core';
import { Close } from '@material-ui/icons';
import { SnackbarProvider, useSnackbar } from 'notistack';
import { notifications, success, error, warning, info } from '@scss/notifications.mod';
import consumer from "@root/channels/consumer"
import { getNotificationSession } from '@root/helpers';

const createSubscription = (onReceive) => {
    consumer.subscriptions.create({ 
        channel: "ActivityChannel", 
        notification_id: getNotificationSession()
    }, {
        connected() {
            // Called when the subscription is ready for use on the server
        },

        disconnected() {
            // Called when the subscription has been terminated by the server
        },

        received: onReceive
    });

}

const getVariant = (state) => { 
    if (state === "failed") {
        return "error";
    }

    return "success";
}

const Notifications = (props) => {
    const { onNotification } = props;

    const { enqueueSnackbar, closeSnackbar } = useSnackbar();
    useEffect(() => {
        createSubscription((data) => {
            enqueueSnackbar(data.message, { variant: getVariant(data.state), persist: true });
            onNotification && onNotification(data);
            
            console.log("received", data);
            // setTimeout(() => window.location.reload(), 2000);
        });
    }, [])

    return <></>;
}

const ActivityNotifications = (props) => {
    const { onNotification } = props; 

    const notistackRef = React.createRef();
    const onClickDismiss = key => () => {
        notistackRef.current.closeSnackbar(key);
    }

    return <SnackbarProvider 
        maxSnack={5}
        anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'right',
        }}
        classes={{
            variantSuccess: `${notifications} ${success}`,
            variantError: `${notifications} ${error}`,
            variantWarning: `${notifications} ${warning}`,
            variantInfo: `${notifications} ${info}`,
        }}
        action={(key) => (
            <Button onClick={onClickDismiss(key)}>
                <Close />
            </Button>
        )}
        ref={notistackRef}
    >
        <Notifications onNotification={onNotification} />
    </SnackbarProvider>;
}

export default ActivityNotifications;