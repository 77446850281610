import React, { useEffect, useState } from "react";
import * as styles from "@scss/searchDropdown.mod";
import { classes } from "@root/helpers";
import axios from 'axios';
import { search_audiences_path } from "routes";

const SearchDropdown = ({ url, onSelect, label, name }) => {
    const [focused, setFocused] = useState(false);
    const [search, setSearch] = useState('');
    const [options, setOptions] = useState([]);
    const [isOpen, setIsOpen] = useState(false);

    const scrollToEnd = () => {
        window.scrollTo({
          top: document.body.scrollHeight,
          behavior: 'smooth'
        });
    };

    const clickOption = (_opt) => {
        setFocused(false);
        const confirmed = confirm(`Do you want to add ${_opt.name} to audiences?`);
        if (confirmed) {
            setSearch(_opt.name);
            setIsOpen(false);
            onSelect(_opt);
            scrollToEnd();
        }
    };

    useEffect(() => {
        if (!focused) return;

        const timeout = setTimeout(() => {
            const renderErrors = (errors) => Object.keys(errors).map((_key, i) => (<span key={i}>{_key} {errors[_key]}</span>));
            const processErrors = (error) => console.warn(error);
            const processResponse = (response) => {
                const { data } = response;
                setOptions(search ? data.audiences : []);
                setIsOpen(!!search);
            };

            axios({
              method: 'GET',
              url: search_audiences_path({ name: search })
            }).then(processResponse)
            .catch(processErrors);
        }, 750)

        return () => clearTimeout(timeout)
    }, [search])

    return (
        <div className={styles.searchDropdown}>
            <label className={styles.field} onBlur={() => { 
                setFocused(false);
                setTimeout(() => setIsOpen(false), 200);
            }} onFocus={() => setFocused(true)}>
                <input 
                    type="text" 
                    className={search && styles.nonempty} 
                    name={name}
                    value={search}
                    onChange={(e) => setSearch(e.target.value)}
                    autoComplete="disabled"
                />
                <span className={styles.placeholder}>{label}</span>
            </label>
            <div className={classes(styles.dropdown, isOpen && styles.open)}>
                {
                    options?.length !== 0 && options?.map((_opt) => <span key={_opt.id} onClick={() => clickOption(_opt)}>{_opt.name}</span>)
                }
                {
                    options?.length === 0 && (<span>No audience found</span>)
                }
            </div>
        </div>
    );
};

export default SearchDropdown;
