import React, { useState, useRef, useEffect } from "react"
import * as styles from '@scss/modalForm.mod';
import CheckCircle from '@material-ui/icons/CheckCircle';
import ErrorIcon from '@material-ui/icons/Error';
import JsonForms from "@components/JsonForms";
import { classes } from '@root/helpers';

const ModalForm = (props) => {
  const [ done, setDone ] = useState(false);
  const [ failed, setFailed ] = useState(false);
  const [ formErrors, setFormErrors ] = useState([]);
  const { exists, schema, uiSchema, name, handleSubmit, handleDelete } = props;

  const [ formData, setFormData ] = useState(props?.data && props.data);
  const onSubmit = () => handleSubmit(exists, formData, formErrors);
  const onDelete = () => handleDelete(exists, formData, formErrors);

  return (
    <div className={styles.modalForm}>
      <div className={styles.modalWrapper}>
        <h3 className={styles.title}>
            { exists ? 'Edit' : 'Add' } {name}
            { done && <CheckCircle className={styles.doneIcon}/> }
            { failed && <ErrorIcon className={styles.errorIcon} /> }
        </h3>
        <div className={classes(styles.form, props.scrollable && styles.scroll)}>
          <JsonForms
              schema={schema}
              uischema={uiSchema}
              data={formData}
              onChange={({ data, errors }) => {
                  setFormData(data);
                  setFormErrors(errors);
              }}
          />
          <div className={styles.buttons}>
            <button className="button-primary" onClick={onSubmit}>Save</button>
            {
              exists && <span className={classes('button-ternary', styles.delete)} onClick={onDelete}>Delete experience</span>
            }
          </div>
        </div>
      </div>
    </div>
  );
}
export default ModalForm;