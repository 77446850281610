const schema = {
    "$schema": "http://json-schema.org/draft-04/schema#",
    "type": "object",
    "definitions": {
        "AudienceRule": { 
            "type": "object",
            "properties": {
                "type": {
                    "type": "string",
                    "enum": [
                        "SITE_PAGE",
                        "USER"
                    ]
                },
                "parameter": {
                    "type": "string",
                    "enum": ["currentPage.path", "currentPage.URL"]
                },
                "operator": {
                    "type": "string",
                    "enum": ["contains", "doesNotContain", "equals", "doesNotEqual", "startsWith", "endsWith", "present", "notPresent", "empty", "notEmpty"]
                },
                "operands": {
                    "type": "array",
                    "items": {
                        "type": "string"
                    }
                }
            }
        },
        "ComplexAudienceRule": {
            "type": "object",
            "properties": { 
                "operator": {
                    "type": "string",
                    "enum": ["and", "or"]
                }, 
                "rules": {
                    "type": "array",
                    "items": {
                        "$ref": "#/definitions/AudienceRule"
                    }
                }
            }
        }
    },
    "properties": {
        "id": {
            "type": "string"
        },
        "name": {
            "type": "string"
        },
        "logic": { 
            "oneOf": [
                {
                    "title": "Single Condition",
                    "$ref": "#/definitions/AudienceRule"
                },
                {
                    "title": "Multiple Conditions",
                    "$ref": "#/definitions/ComplexAudienceRule"
                }
            ]
        }
    },
    "required": [
        "id",
        "name",
        "logic"
    ]
};

const uiSchema = {
    "type": "VerticalLayout",
    "elements": [
        {
            "type": "HorizontalLayout",
            "elements": 
            [
                {
                    "type": "Control",
                    "label": "ID",
                    "scope": "#/properties/id"
                },
                {
                    "type": "Control",
                    "label": "Name",
                    "scope": "#/properties/name"
                }
            ]
        },
        {
            "type": "Control",
            "label": "Multiple conditions",
            "scope": "#/properties/logic"
        }
    ]
};

export {
    schema,
    uiSchema
};
