import React, { useState, useEffect } from 'react';
import { useMediaQuery } from 'react-responsive';
import * as styles from '@scss/boxList.mod';
import Trademark from '@components/Trademark';
import TemplateExample from '@components/Template/TemplateExample';
import Box from '@components/Box/Box';
import Sort from '@components/Sieve/Sort';
import Search from '@components/Sieve/Search';
import { classes } from '@root/helpers';

const TemplateList = (props) => {
    const { templates, current_user } = props;
    const image = templates?.length && templates[0]?.screenshots?.length && templates[0].screenshots[0];
    const [details, setDetails] = useState({ name: templates[0]?.name, description: templates[0]?.schema.description, scr: image, availableMarkets: templates[0]?.available_market });
    const [overlay, setOverlay] = useState(false);
    const [ _ , setTemplate] = useState(templates[0].name);

    const [templateOpen, setTemplateOpen] = useState(false);
    const isDesktopOrLaptop = useMediaQuery({ minWidth: 1024 });
    const isTabletOrMobile = useMediaQuery({ maxWidth: 1023 });

    const [sortAsc, setSortAsc] = useState(true);
    const [searchData, setSearchData] = useState(null);
    const [filtered, setFiltered] = useState([]);

    const sortCondition = (a, b) => {
        if (a.created_at == b.created_at) return 0;
        return sortAsc && a.created_at < b.created_at ? 1 : -1;
    }

    const searchCondition = (a) => a.name.match(searchData);

    useEffect(() => {
        setTimeout(() => setFiltered(templates), 100);
    }, []);

    useEffect(() => {
        setFiltered(templates.filter(searchCondition));
    }, [searchData]);

    return (
        <div className={styles.templateWrapper}>
            <div className={styles.templateContainer}>
                {
                    details.scr &&
                    <div className={overlay ? styles.overlay : styles.hide} onClick={() => setOverlay(false)}>
                        <img src={details.scr} className={classes('pure-img', styles.overlayImg)} alt="Scr" />
                    </div>
                }
                <div className={styles.header}>
                    <h3>Templates</h3>
                    <Search setSearchData={setSearchData} />
                </div>
                <div className={styles.sieve}>
                    <Sort
                        sortAsc={sortAsc}
                        setSortAsc={setSortAsc}
                        options={['Newest first', 'Oldest first']}
                    />
                </div>
                <div className={styles.template}>
                    {
                        filtered.sort(sortCondition).map((sortedTemplate) => (
                            <Box
                                key={sortedTemplate.name}
                                content={sortedTemplate}
                                setContent={setTemplate}
                                setDetails={setDetails}
                                setContentOpen={setTemplateOpen}
                                isDesktopOrLaptop={isDesktopOrLaptop}
                            />
                        ))
                    }
                </div>
                <Trademark />
            </div>
            <div className={styles.template}>
                <TemplateExample
                    details={details}
                    current_user={current_user}
                    setOverlay={setOverlay}
                    templateOpen={templateOpen}
                    setTemplateOpen={setTemplateOpen}
                />
                <div className={styles.placement} />
            </div>
            {isTabletOrMobile && <div className={styles.mobilePlacement} />}
        </div>
    );
}

export default TemplateList;
