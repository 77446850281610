import React, { useState } from 'react';
import Banner from '@components/Banner';
import left from '@images/LeftFaq.png';
import right from '@images/RightFaq.png';
import styles from '@scss/faq.mod';
import { classes } from '@root/helpers';

const FAQDropdown = ({ title, paragraph }) => {
    const [clicked, setClicked] = useState(false);
    const toggleDropdown = () => setClicked(!clicked);

    return (
        <div className={classes("pure-u-1 pure-u-md-1-2", styles.dropdownWrapper)}>
            <div className={classes(styles.dropdowns, clicked && styles.drop)} onClick={toggleDropdown}>
                <div className={styles.heading}>
                    <h1> {title} </h1>
                    <div className={classes(styles.arrowBorder, clicked && styles.opened)}>
                        <span className={styles.arrayIcon} />
                    </div>
                </div>
                <div className={styles.paragraph}>{paragraph}</div>
            </div>
        </div>
    );
};

const FAQ = () => (
    <React.Fragment>
        <Banner 
            leftImg={left} 
            heading='FAQs' 
            paragraph='To resolve some of the most common doubts, here are answers to some frequently asked questions:'
            rightImg={right}
            reverse={true}
        />
        <div className={classes("pure-g", styles.container)}>
            <FAQDropdown
                title="I need support during the weekend. Will I receive it?"
                paragraph=''
            />
            <FAQDropdown
                title="I'm not a technical person. Is this a problem?"
                paragraph=''
            />
            <FAQDropdown
                title="I have a development question. Where should I address it to?"
                paragraph=''
            />
            <FAQDropdown
                title="What is the purpose of the deploy button"
                paragraph=''
            />
            <FAQDropdown
                title="What about third party systems/plugins?"
                paragraph=''
            />
            <FAQDropdown
                title="I need a developer license for a test instance"
                paragraph=''
            />
            <FAQDropdown
                title="What is a customisation?"
                paragraph=''
            />
            <FAQDropdown
                title="What is a customisation?"
                paragraph=''
            />
        </div>
    </React.Fragment>
);

export default FAQ;
