import React, { useState, useRef, useCallback } from 'react';
import { 
    root_path, 
    destroy_user_session_path 
} from 'routes';
import * as styles from '@scss/mobileTopSidebar.mod';
import Cheil from '@images/cheil.svg';
import Logout from '@images/logout.png';
import { classes, useOnClickOutside } from '@root/helpers';

const MobileTopSidebar = () => {
    const ref = useRef();
    const [ log, setLog ] = useState(false);
    useOnClickOutside(ref, useCallback(() => setLog(false), []));

    return (
        <div className={styles.topSide}>
            <div className={classes('pure-menu', styles.topSideWrapper)}>
                <div className={styles.topSideContainer}>
                    <a href={root_path()}>
                        <img src={Cheil} className={classes('pure-img', styles.logo)} alt="logo" />
                    </a>
                    <div className={styles.topSideLeft}>
                        <img src={Logout} className={classes('pure-img', styles.logoutImg)} alt="Logout" onClick={() => setLog(true)}/>
                        <ul className={classes('pure-menu-list', !log && styles.hide)} ref={ref}>
                            <li className={classes('pure-menu-item', styles.topSideLink)}>
                                <a className={classes('pure-menu-link', styles.link)} href={destroy_user_session_path()}>
                                    Log out
                                </a>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default MobileTopSidebar;