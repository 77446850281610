import React, { useState, useEffect } from 'react';
import { useMediaQuery } from 'react-responsive';
import * as styles from '@scss/toolPlayground.mod';
import Trademark from '@components/Trademark';
import Arrow from '@images/arrow.svg';
import CheckCircle from '@material-ui/icons/CheckCircle';
import LoopIcon from '@material-ui/icons/Loop';
import ErrorIcon from '@material-ui/icons/Error';
import { classes } from '@root/helpers';
import { filter, defaultSchema } from '@schemas/Tool/JSONTransform';

import { createSchema } from 'genson-js';
import GenerateSchema from 'generate-schema';
import ReactJsonView from 'react-json-view';

import AceEditor from 'react-ace';
import 'ace-builds/webpack-resolver';
import 'ace-builds/src-noconflict/mode-json';
import 'ace-builds/src-noconflict/theme-github';
import 'ace-builds/src-noconflict/keybinding-emacs';
import 'ace-builds/src-noconflict/keybinding-vim';

const TransformPlayground = () => {
    const [changeStatus, setChangeStatus] = useState(null);
    const isTabletOrMobile = useMediaQuery({ maxWidth: 1023 });
    const [ schema, setSchema ] = useState(JSON.stringify(defaultSchema, null, 2));
    const [ currentSchema, setCurrentSchema ] = useState(GenerateSchema.json(schema));
    const [ schemaType, setSchemaType ] = useState(0);


    const onChange = (newValue) => {
        setChangeStatus('loading');
        setSchema(newValue);
        try {
            const newSchema = JSON.parse(newValue, null, 2);
            if (schemaType === 0) 
                setCurrentSchema(GenerateSchema.json(newSchema));
            else
                setCurrentSchema(createSchema(newSchema));
            
            setChangeStatus('done');

            setTimeout(() => setChangeStatus(null), 1000);
        } catch (ex) {
            console.log("Invalid form.");
            setChangeStatus('failed');
        }
    }
        
    useEffect(() => onChange(schema), [schemaType]);

    return (
        <div className={styles.toolWrapper}>
            <div className={styles.toolContainer}>
                <div className={styles.header}>
                    <h3>JSON Transform Playground</h3>
                    { changeStatus == "loading" && <LoopIcon className={styles.doneIcon} /> }
                    { changeStatus == "done" && <CheckCircle className={styles.doneIcon} /> }
                    { changeStatus == "failed" && <ErrorIcon className={styles.errorIcon} /> }
                </div>
                {filter &&
                    <div className={styles.filter}>
                        <div className={styles.activityFilter}>
                            <div className={styles.searchBtn}>
                                <h6>{ schemaType == 0 ? filter.format1 : filter.format2 }</h6>
                                <img src={Arrow} className={classes('pure-img', styles.arrow)} alt="Arrow" />
                                <ul className="pure-menu-list">
                                    <li className="pure-menu-item">
                                        {
                                            schemaType == 0 ?
                                                <a className={`pure-menu-link ${styles.link}`} onClick={() => setSchemaType(1)}>
                                                    {filter.format2}
                                                </a>
                                                :
                                                <a className={`pure-menu-link ${styles.link}`} onClick={() => setSchemaType(0)}>
                                                    {filter.format1}
                                                </a>
                                        }
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                }
                <div className={styles.toolInner}>
                    <AceEditor
                        mode="json"
                        theme="textmate"
                        name="json-editor"
                        showPrintMargin={true}
                        showGutter={true}
                        highlightActiveLine={true}
                        value={schema}
                        enableBasicAutocompletion={false}
                        enableLiveAutocompletion={false}
                        enableSnippets={false}
                        setOptions={{
                            showLineNumbers: true,
                            tabSize: 2
                        }}
                        editorProps={{ $blockScrolling: true }}
                        onChange={(e) => onChange(e)}
                        keyboardHandler='emacs'
                        debounceChangePeriod={1000}
                    />
                    <div className={styles.formWrapper}>
                        <ReactJsonView src={currentSchema} />
                    </div>
                </div>
                <Trademark />
            </div>
            { isTabletOrMobile && <div className={styles.mobilePlacement} /> }
        </div>
    );
}

export default TransformPlayground;