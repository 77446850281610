import React, { useRef, useState } from 'react';
import { Button, ButtonGroup, Chip, ClickAwayListener, Container, Grow, makeStyles, MenuItem, MenuList, Paper, Popper, Table, TableBody, TableCell, TableHead, TableRow } from '@material-ui/core';
import { ArrowDropDown } from '@material-ui/icons';
import { users_path, new_user_path, edit_user_path, change_status_user_path } from 'routes';
import { withOurTheme } from './theme';
import axios from 'axios';

const useStyles = makeStyles({
    table: {
        minWidth: 650,
    },
    menuItemLink: {
        '&:hover': {
            textDecoration: 'none',
        },
    },
});

const UserRow = (props) => {
    const [user, setUser] = useState(props.user);
    const [approveDisabled, setApproveDisabled] = useState(false);
    const [declineDisabled, setDeclineDisabled] = useState(false);
    const [blockDisabled, setBlockDisabled] = useState(false);

    const [open, setOpen] = useState(false);
    const anchorRef = useRef(null);

    const handleToggle = () => { setOpen(!open) }

    const handleClose = (event) => {
        if (anchorRef.current && anchorRef.current.contains(event.target)) {
            return;
        }

        setOpen(false);
    };

    const handleApprove = () => {
        setApproveDisabled(true);

        axios.put(change_status_user_path(user.id, "approved")).then((response) => {
            window.location.href = users_path()
        }).finally(() => setApproveDisabled(false));
    }

    const handleDecline = () => {
        setDeclineDisabled(true);

        axios.put(change_status_user_path(user.id, "declined")).then((response) => {
            window.location.href = users_path()
        }).finally(() => setDeclineDisabled(false));
    }

    const handleBlock = () => {
        setBlockDisabled(true);

        axios.put(change_status_user_path(user.id, "blocked")).then((response) => {
            window.location.href = users_path()
        }).finally(() => setBlockDisabled(false));
    }

    return (<TableRow>
        <TableCell component="th" scope="row">
            {user.email}
        </TableCell>
        {/* <TableCell>
            {user.role}
        </TableCell> */}
        <TableCell>
            {user.market}
        </TableCell>
        <TableCell>
            {!user.status && <Chip label="Not specified yet."/>}
            {user.status === "pending" && <Chip label="Pending..." />}
            {user.status === "declined" && <Chip label="Declined" color="primary" />}
            {user.status === "approved" && <Chip label="Approved" color="primary" />}
            {user.status === "blocked" && <Chip label="Blocked" color="secondary" />}
        </TableCell>
        <TableCell>
            <ButtonGroup ref={anchorRef} variant="contained" color="primary">
                <Button href={edit_user_path(user.id)}>Edit</Button>
                <Button
                    color="primary"
                    size="small"
                    onClick={handleToggle}
                >
                    <ArrowDropDown />
                </Button>
            </ButtonGroup>
            <Popper open={open} anchorEl={anchorRef.current} role={undefined} transition disablePortal style={{zIndex: 2}}>
                {({ TransitionProps, placement }) => (
                    <Grow
                        {...TransitionProps}
                        style={{
                            transformOrigin: placement === 'bottom' ? 'center top' : 'center bottom',
                        }}
                    >
                        <Paper>
                            <ClickAwayListener onClickAway={handleClose}>
                                <MenuList>
                                    <MenuItem disabled={approveDisabled || user.status === "approved"} onClick={handleApprove}>
                                        Approve
                                    </MenuItem>
                                    <MenuItem disabled={declineDisabled || user.status === "declined" || user.status === "blocked" || user.status === "approved"} onClick={handleDecline}>
                                        Decline
                                    </MenuItem>
                                    <MenuItem disabled={blockDisabled || user.status === "blocked"} onClick={handleBlock}>
                                        Block
                                    </MenuItem>
                                </MenuList>
                            </ClickAwayListener>
                        </Paper>
                    </Grow>
                )}
            </Popper>
        </TableCell>
    </TableRow>)
}

const UserList = (props) => {
    const classes = useStyles();
    const { users } = props;

    return <Container maxWidth={"lg"}>
        <Button variant="contained" color="primary" href={new_user_path()}>
            New User
        </Button>

        <Table className={classes.table}>
            <TableHead>
                <TableRow>
                    <TableCell>Email</TableCell>
                    {/* <TableCell>Role</TableCell> */}
                    <TableCell>Market</TableCell>
                    <TableCell>Status</TableCell>
                    <TableCell>Actions</TableCell>
                </TableRow>
            </TableHead>
            <TableBody>
                {
                    users.map((user) => (
                        <UserRow 
                            user={user} 
                            key={user.id} 
                        />
                    ))
                }
            </TableBody>
        </Table>
    </Container>
}

export default withOurTheme(UserList);
