import { useEffect } from 'react';
import axios from 'axios';
import { by_token_activities_path } from 'routes';
import _lo from 'lodash';

export const getIitials = (fullName) => {
    if (!fullName) return 'I';
    const namesArray = fullName.trim().split(' ');
    if (namesArray.length === 1) return `${namesArray[0].charAt(0)}`;
    else return `${namesArray[0].charAt(0)}${namesArray[namesArray.length - 1].charAt(0)}`;
};

export const getTemplateName = (name) => {
    let regex = /-/gi;
    const newName = name.replace(regex, " ");
    return (newName.charAt(0).toUpperCase() + newName.slice(1));
};

export const extendVariationSchema = (template) => {
  if (!template || !template?.schema) return template;

  const _template = _lo.cloneDeep(template);

  const propsTemplate = (props, required) => ({
    "variationContents": {
      "type": "array",
      "title": "Variation Contents",
      "items": {
        "type": "object",
        "properties": {
          "configName": {
              "title": "Config Name",
              "type": "string"
          },
          ...props
        },
        "required": [
          "configName",
          ...required
        ]
      }
    }
  });

  const uiSchemaTemplate = {
    "type": "VerticalLayout",
    "elements": [
      {
        "type": "HorizontalLayout",
        "elements": [
          {
            "type": "Control",
            "scope": "#/properties/variationContents"
          }
        ]
      }
    ]
  };

  _template.schema.properties = propsTemplate(_template.schema.properties, _template.schema.required);
  _template.schema.required = [ "variationContents" ];
  _template.ui_schema = uiSchemaTemplate;

  return _template;
};

export const restrictAudConfigsSources = (activityType, template, targetSchema, config) => {
  if (!template || !targetSchema || !config['variationContents']) return;

  const availableSources = template.available_sources;
  const availableConfigs = config['variationContents'].map((content) => content.configName);
  const schema = activityType === "A-B" ? targetSchema[0] : targetSchema[1];
  const sourcePaths = activityType === "A-B" ? [
    'properties.experiences.oneOf[0].items.properties.audiences.properties.source.enum',
    'properties.experiences.oneOf[1].items.properties.audiences.items.properties.source.enum'
  ] : ['properties.experiences.items.properties.audiences.properties.source.enum'];
  const configPaths = activityType === "A-B" ? [
    'properties.experiences.oneOf[0].items.properties.audiences.properties.variationContent.enum',
    'properties.experiences.oneOf[1].items.properties.audiences.items.properties.variationContent.enum'
  ] : ['properties.experiences.items.properties.audiences.properties.variationContent.enum'];

  sourcePaths.forEach((path) => _lo.set(schema, path, availableSources));
  configPaths.forEach((path) => _lo.set(schema, path, availableConfigs));
  return schema;
};

export const useOnClickOutside = (ref, handler) => {
    useEffect(
      () => {
        const listener = (event) => {
          if (!ref.current || ref.current.contains(event.target)) {
            return;
          }
          handler(event);
        };
        document.addEventListener("mousedown", listener);
        document.addEventListener("touchstart", listener);
        return () => {
          document.removeEventListener("mousedown", listener);
          document.removeEventListener("touchstart", listener);
        };
      },
      [ref, handler]
    );
};

export const classes = (...classes) => classes.filter((item) => !!item).join(' ');

export const sendExtMessage = (payload, callback) => window.chrome.runtime.sendMessage(
    CHROME_EXTENSION_ID, payload, callback
);

export const selectedMarket = () => sessionStorage.getItem("selectedMarket");

export const extVersion = CHROME_EXTENSION_VER;

export const getNotificationSession = () => {
  const notificationField = document.querySelector('[name="notification-id"');
  const notificationId = notificationField.value;
  return notificationId || null;
};

export const outsidePage = (pageList) => pageList.some((page) => window.location.pathname.match(page));

export const copyToClipboard = (textToCopy) => {
  if (navigator.clipboard && window.isSecureContext) {
    navigator.clipboard.writeText(textToCopy);
  } else {
    // text area method
    let textArea = document.createElement("textarea");
    textArea.value = textToCopy;
    textArea.style.position = "fixed";
    textArea.style.left = "-999999px";
    textArea.style.top = "-999999px";
    document.body.appendChild(textArea);
    textArea.focus();
    textArea.select();
    return new Promise((res, rej) => {
      document.execCommand('copy') ? res() : rej();
      textArea.remove();
      alert("Copied to clipboard");
    });
  }
}

export const fetchForCopy = async (tokenForContent) => {
  return axios.get(by_token_activities_path(null, {token: tokenForContent, prod_build: true})).then((response) => {
    if (response.data.error) return;

    const assembled_script = response.data.assembled_script;
    copyToClipboard(assembled_script);
  });
}
