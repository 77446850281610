import React from "react"
import { JsonForms } from '@jsonforms/react';
import {
  JsonFormsStyleContext, vanillaStyles,
  vanillaCells, vanillaRenderers
} from '@jsonforms/vanilla-renderers';
import {
  materialCells, materialRenderers
} from '@jsonforms/material-renderers';
import * as styles from '@scss/jsonForms.mod';
import CheckboxControl from './CheckboxRenderer/CheckboxControl';
import CheckboxControlTester from './CheckboxRenderer/CheckboxControlTester';
import InputControlTester from "./InputRenderer/InputControlTester";
import InputControl from "./InputRenderer/InputControl";

const styleContextValue = {
  styles: [
    ...vanillaStyles,
    {
      name: 'horizontal.layout',
      classNames: [styles.horizontalLayout]
    },
    {
      name: 'horizontal.layout.item',
      classNames: [styles.horizontalItem]
    },
    {
      name: 'vertical.layout',
      classNames: [styles.verticalLayout]
    },
    {
      name: 'vertical.layout.item',
      classNames: [styles.verticalItem]
    },
    {
      name: 'control',
      classNames: [styles.control]
    },
    {
      name: 'control.label',
      classNames: [styles.label]
    },
    {
      name: 'control.input',
      classNames: [styles.input]
    },
    {
      name: 'control.select',
      classNames: [styles.select]
    },
    {
      name: 'array.table',
      classNames: [styles.arrayTable]
    },
    {
      name: 'array.table.table',
      classNames: [styles.table]

    },
    {
     name: 'array.table.button',
     classNames: [styles.button]
    },
    {
      name: 'array.table.label',
      classNames: [styles.label]
    },
    {
      name: 'array.table.validation.error',
      classNames: [styles.validationError]
    },
    {
      name: 'control.validation',
      classNames: [styles.validation]
    },
    {
      name: 'control.validation.error',
      classNames: [styles.error]
    },
    {
      name: 'fieldset',
      classNames: [styles.fieldset]
    },
    {
      name: 'fieldset.horizontal.layout.item',
      classNames: [styles.horizontalItem]
    },
    {
      name: 'fieldset.horizontal.layout.label',
      classNames: [styles.label]
    },
    {
      name: 'fieldset.horizontal.layout.input',
      classNames: [styles.input]
    },
    {
      name: 'fieldset.control',
      classNames: [styles.control]
    }

    
  ]
};

const vanRenderers = [
  ...vanillaRenderers,
  {tester: CheckboxControlTester, renderer: CheckboxControl },
  {tester: InputControlTester, renderer: InputControl}
];

const VanillaForm = (props) => (
  <div className={styles.form}>
    <JsonFormsStyleContext.Provider value={styleContextValue}>
      <JsonForms
        renderers={vanRenderers}
        cells={vanillaCells}
        { ...props}
        
      />
    </JsonFormsStyleContext.Provider>
  </div>
);

const matRenderers = [
  ...materialRenderers,
  {tester: CheckboxControlTester, renderer: CheckboxControl },
  {tester: InputControlTester, renderer: InputControl}
];

const MaterialForm = (props) => (
  <div className={styles.form}>
    <JsonForms
      renderers={matRenderers}
      cells={materialCells}
      { ...props}
      
    />
  </div>
);

export default MaterialForm;
