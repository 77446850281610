import React, { useState } from "react"
import { JsonForms } from '@jsonforms/react';
import { materialCells, materialRenderers } from '@jsonforms/material-renderers';
import { Box, Button, Container, makeStyles, Paper, Tab, Tabs, Typography, GridList, GridListTile, GridListTileBar, IconButton } from '@material-ui/core';
import OpenInNewIcon from '@material-ui/icons/OpenInNew';
import { users_path } from 'routes';
import _lo from 'lodash';

import theme, { withOurTheme } from './theme';

import axios from 'axios';

const useStyles = makeStyles(theme => ({
  expansionPanel: {
    marginBottom: theme.spacing(4)
  },
  paper: { 
    padding: theme.spacing(2, 1)
  },
  gridList: {
    flexWrap: 'nowrap',
    transform: 'translateZ(0)',
    marginBottom: '30px !important',
  },
}));

const UserForm = (props) => { 
  const classes = useStyles();
  const { user } = props

  const [ formErrors, setFormErrors ] = useState([]);
  const [ formData, setFormData ] = useState(user && user.id ? {email: user.email, market: user.market} : {email: '', market: 'de'});

  const defaultSchema = { 
    "type": "object",
    "properties": {
      "email": {
        "title": "Email",
        "type": "string",
      },
      "market": {
        "title": "Market",
        "type": "string",
        "enum": ["be", "befr", "ca", "de", "dk", "es", "fi", "fr", "it", "nl", "no", "pl", "se", "uk", "eu"],
      }
    },
    "required": [
      "email",
      "market"
    ]
  }

  const handleSubmit = () => {
    if (formErrors.length === 0) { 
      if (user && user.id) { 
        axios.put(`/users/${user.id}.json`,  {...formData}).then((response) => { 
          window.location.href = users_path()
        });
      } else {
        axios.post('/users.json', {...formData}).then((response) => { 
          window.location.href = users_path()
        });
      }
    }
  }

  return (
    <Container maxWidth={"lg"}>
      <Typography variant="h6" className={classes.expansionPanel}>{user && user.id ? 'Edit User' : 'New User'}</Typography>
      <JsonForms
        schema={defaultSchema}
        data={formData}
        renderers={materialRenderers}
        cells={materialCells}
        onChange={({ data, errors }) => {
          setFormData(data);
          setFormErrors(errors);
        }}
      />     

      <Button variant="contained" color="primary" onClick={handleSubmit}>Save</Button>
    </Container>
  );
}
export default withOurTheme(UserForm)
