import React from 'react';
import * as styles from '@scss/trademark.mod';
import SogodyLogo from '@images/sogodyLogo.png';
import { classes } from '@root/helpers';

const Trademark = () => {
  return (
    <div className={styles.trademark}>
        A product built by
        <a href="https://sogody.com/" target="_blank">
            <img src={SogodyLogo} className={classes('pure-img', styles.sogodyLogo)} alt="Sogody Logo" />
        </a>
    </div>
  );
}

export default Trademark;