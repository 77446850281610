import React from 'react';
import * as styles from '@scss/search.mod';
import { classes } from '@root/helpers';
import SearchImg from '@images/search.svg';

const Search = (props) => (
    <div className={styles.searchContainer}>
        <input id="search" placeholder="Search" name="search" type="text" onChange={props.setSearchData}/>
        <img src={SearchImg} className={classes('pure-img', styles.searchImg)} alt="Search" />
    </div>
);

export default Search;
