import React, { useState } from 'react';
import * as modalStyles from '@scss/modalForm.mod';
import * as styles from '@scss/previewLinkForm.mod';
import JsonForms from "@components/JsonForms";
import { schema, uiSchema } from '@schemas/PreviewLink';
import { classes, fetchForCopy } from '@root/helpers';
import isURL from 'validator/lib/isURL'
import LoaderGif from '@images/Loader.gif';

export default function PreviewLinkForm(props) {
  const { variations, scrollable, content } = props;
  const [ formErrors, setFormErrors ] = useState([]);
  const [ loading, setLoading ] = useState(false);
  const [ formData, setFormData ] = useState(props?.data || { url: 'https://' });

  const addHttp = (url) => isURL(url, { require_protocol: true }) ? url : `http://${url}`;
  const targetResetParams = '&at_preview_token=testful&at_preview_listed_activities_only=true';
  const domain = `&domain=${window.location.origin}`;

  const hasValidUrl = formData?.url && isURL(formData.url);
  const generateUrl = (variation) => formData?.url && `${addHttp(formData.url)}${variation.base}${variation.dev}${formData.editable ? variation.editable : ''}${targetResetParams}${domain}`;
  const linkClass = () => hasValidUrl ? '' : styles.disabled;

  const isClipboard = content === 'clipboard';
  let loaderTimeout = null;

  const copyToClipboard = async (variation) => {
    setLoading(true);
    fetchForCopy(variation.token).then(() => {
      setLoading(false);
    });
  }

  const VariationsContent = ({ variations }) => (
    <>
        {
            variations && variations.map((variation, index) => {
              return (
                <span key={index}>
                  <a className={linkClass()} target="_blank" href={generateUrl(variation)}>{variation.name} {variation.source}</a>
                  <br/>
                </span>
              );
            })
        }
    </>
  );

  const VariationsClipboard = ({ variations }) => (
    <>
        {
            variations && variations.map((variation, index) => {
              return (
                <span key={index}>
                  <a target="_blank" onClick={() => copyToClipboard(variation)}>Variation {index + 1}</a>
                  <br/>
                </span>
              );
            })
        }
    </>
  );

  const Preview = ({ variations }) => {
    if (loading) return (
      <div className={styles.loader}>
        <img src={LoaderGif} alt="Loading" />
      </div>
    );

    if (isClipboard) {
      return (
        <>
          <span><b>Copy script</b></span>
          <VariationsClipboard variations={variations} />
        </>
      );
    }

    if (formData?.url && isURL(formData.url)) return (
      <>
        <span><b>Preview Links</b></span>
        <VariationsContent variations={variations} />
      </>
    )

    return <></>;
  };

  return (
    <div className={modalStyles.modalForm}>
      <div className={modalStyles.modalWrapper}>
        <h3 className={styles.titleSpace}>
          { isClipboard ? "Copy to Clipboard" : "Build Preview Link" }
        </h3>
        <div className={classes(modalStyles.form, scrollable && modalStyles.scroll)}>
          { !isClipboard && 
            <JsonForms
                schema={schema}
                uischema={uiSchema}
                data={formData}
                onChange={({ data, errors }) => {
                    if (loading) clearTimeout(loading);
                    const loaderTimeout = setTimeout(() => setLoading(false), 500);
                    setLoading(loaderTimeout);
                    setFormData(data);
                    setFormErrors(errors);
                }}
            />
          }
          <div className={styles.preview}>
            { variations && <Preview variations={variations}/> }
          </div>
        </div>
      </div>
    </div>
  );
}