import axios from 'axios';
import React, { useState, useEffect, useCallback, useMemo } from 'react';
import { useMediaQuery } from 'react-responsive';
import { new_activity_path, filter_activities_path } from 'routes';
import * as styles from '@scss/activityList.mod';
import Plus from '@images/plus.svg';
import ActivityNotifications from '@components/Activity/ActivityNotifications';
import ActivityRow from '@components/Activity/ActivityRow';
import Trademark from '@components/Trademark';
import Search from '@components/Sieve/Search';
import Filter from '@components/Sieve/Filter';
import Pagination from '@components/Pagination';
import Modal from '@components/Modal';
import PreviewLinkForm from '@components/PreviewLinkForm';
import { classes, selectedMarket } from '@root/helpers';

const ActivityList = (props) => {
    const { current_user } = props;
    const [ openDialog, setOpenDialog ] = useState(false);
    const [ dialogVariations, setDialogVariations ] = useState(null);
    const [ dialogContent, setDialogContent ] = useState(null);
    const [ searchData, setSearchData ] = useState(null);
    const [ activitiesLoading, setActivitiesLoading ] = useState(false);

    const [ page, setPage ] = useState(null);
    const [ pages, setPages ] = useState(null);
    const [ activities, setActivities ] = useState([]);
    const [ filterMarket, setFilterMarket ] = useState(selectedMarket() && selectedMarket() !== current_user.market);
    const [ slcMarket, setSlcMarket ] = useState(selectedMarket());

    const isTabletOrMobile = useMediaQuery({ maxWidth: 1023 });

    const getActivities = useCallback((market, pg, search) => {
        setActivitiesLoading(true);
        axios.get(filter_activities_path({market: market, page: pg, pattern: search})).then((response) => {
            setPage(response.data.page);
            setPages(response.data.pages);
            setActivities(response.data.activities);
            setActivitiesLoading(false);
        });
    }, [])

    const handleSearch = useCallback((e) => {
        setSearchData(e.target.value)
    }, []);

    useEffect(() => {
        setSlcMarket(selectedMarket());
    }, []);

    useEffect(() => {
        clearTimeout(window.__searchTimeout);
        window.__searchTimeout = setTimeout(() => {
            getActivities(slcMarket, 1, searchData?.toLowerCase());
            if (slcMarket === current_user.market) setFilterMarket(false);
        }, 500);
    }, [searchData]);

    useEffect(() => {
        getActivities(slcMarket, page, searchData?.toLowerCase());
        if (slcMarket === current_user.market) setFilterMarket(false);
    }, [page]);

    useEffect(() => {
        getActivities(slcMarket, 1, searchData?.toLowerCase());
        if (slcMarket === current_user.market) setFilterMarket(false);
    }, [slcMarket]);

    return (
        <div className={styles.activityWrapper}>
            <div className={styles.activityContainer}>
                <ActivityNotifications />
                <div className={styles.header}>
                    <h3>Activities</h3>
                    <Search setSearchData={handleSearch} />
                </div>
                <div className={styles.sieve}>
                    <Filter 
                        current_user={current_user}
                        conditionParam={filterMarket} 
                        setConditionParam={setFilterMarket} 
                        slcMarket={slcMarket}
                        setSlcMarket={(option) => {
                            setSlcMarket(option);
                            setPage(1);
                        }}
                        firstOptions={['My market', 'Other markets']}
                        secondOptions={['all', 'be', 'befr', 'ca', 'de', 'dk', 'es', 'fi', 'fr', 'it', 'nl', 'no', 'pl', 'se', 'uk', 'eu_al', 'eu_at', 'eu_ba', 'eu_bg', 'eu_hr', 'eu_cz', 'eu_ee', 'eu_gr', 'eu_hu', 'eu_ie', 'eu_lv', 'eu_lt', 'eu_mk', 'eu_pt', 'eu_ro', 'eu_rs', 'eu_sk', 'eu_si', 'eu_ch', 'eu_chfr'
                    ]}
                    />
                    <a className={classes('pure-button', 'button-primary', styles.btn)} href={new_activity_path()}>
                        <img src={Plus} className={classes('pure-img', styles.plus)} alt="Plus" />
                        New Activity
                    </a>
                </div>
                <table className={classes('pure-table', 'pure-table-horizontal', styles.table)}>
                    <thead className={styles.head}>
                        <tr>
                            <th>Status</th>
                            <th>Market</th>
                            <th>Name</th>
                            <th>Template</th>
                            <th>Last updated at</th>
                            <th>Owner</th>
                            <th></th>
                            <th>Options</th>
                            <th></th>
                        </tr>
                    </thead>
                    <tbody className={styles.body}>
                        {
                            activities?.map((activity) => (
                                <ActivityRow 
                                    activity={activity} 
                                    current_user={current_user}
                                    key={activity.id} 
                                    setOpenDialog={setOpenDialog} 
                                    setDialogContent={setDialogContent} 
                                    setDialogVariations={setDialogVariations}
                                />
                            ))
                        }
                    </tbody>
                </table>
                { pages > 1 && <Pagination page={page} setPage={setPage} pages={pages} pgDisplay={3} status={activitiesLoading} /> }
                <Trademark />
                <Modal visible={openDialog} onClose={() => setOpenDialog(false)}>
                    <PreviewLinkForm variations={dialogVariations} content={dialogContent} />
                </Modal>
            </div>
            { isTabletOrMobile && <div className={styles.mobilePlacement} /> }
        </div>
    );
}

export default ActivityList;
