import React from 'react';
import * as styles from '@scss/box.mod';
import Customer from '@images/customer.svg';
import { classes } from '@root/helpers';

const AudienceBox = ({ audience, openForm }) => {
    const config = audience?.config;
    const operands = config?.logic?.operands?.slice(0, 1).join(', ');

    return (
        <div className={styles.box}>
            <div className={styles.boxContainer}>
                <div className={styles.circle}>
                    <img src={Customer} className={classes('pure-img', styles.tempSmallImg)} alt="customer" />
                </div>
                <h6>{ audience.name }</h6>
                <p className={styles.id}>ID: { config?.id }</p>
                <div className={classes(styles.param, styles.first)}>
                    <p className={styles.name}>Parameter</p>
                    <p className={styles.value}>{ config?.logic?.parameter }</p>
                </div>
                <div className={styles.param}>
                    <p className={styles.name}>Operator</p>
                    <p className={styles.value}>{ config?.logic?.operator }</p>
                </div>
                <div className={styles.param}>
                    <p className={styles.name}>Operands</p>
                    <p className={styles.value}>{ operands }</p>
                </div>
                <a className={classes('pure-button', 'button-secondary', styles.btn)} onClick={openForm}>
                    Edit
                </a>
            </div>
        </div>
    );
}

export default AudienceBox;