import React, { useState, useRef } from "react"
import * as styles from '@scss/home.mod';
import Logo from '@images/logo.png';
import SogodyLogo from '@images/sogodyLogo.png';
import LandingAnimation from '@images/landingAnimation.gif';
import Laptop from '@images/laptop.png';
import Notification from '@images/notification.svg';
import Modal from '@components/Modal';
import RegisterForm from '@components/RegisterForm';
import { classes } from '@root/helpers';

const Home = () => {
  const [email, setEmail] = useState('');
  const [modalOpen, setModalOpen] = useState(false);

  return (
    <div className={classes('pure-g', styles.homeWrapper)}>
        <div className={classes('pure-g', styles.homeContainer)}>
            <div className={classes("pure-u-1 pure-u-lg-14-24", styles.contentWrapper)}>
                <h1>Activities deployed in seconds</h1>
                <h4>
                    Creating and deploying A/B activities has never been easier. 
                    Testful lets you preview of built activities without the need of Adobe Target.
                </h4>
                <div className={styles.form}>
                    <label className={styles.customField}>
                        <input 
                            className={email ? styles.filled : '' } 
                            type="email" required onChange={(event) => setEmail(event.target.value)}
                        />
                        <span className={styles.placeholder}>Enter your email address</span>
                    </label>
                    <a onClick={() => setModalOpen(true)} className="button-primary">Request Access</a>
                </div>

                <div className={styles.product}>
                    <h6>A product built by</h6>
                    <a href="#">
                        <img src={SogodyLogo} className={classes('pure-img', styles.sogodyLogo)} alt="Sogody Logo" />
                    </a>
                </div>
            </div>
            <div className={classes("pure-u-1 pure-u-lg-10-24", styles.imageWrapper)}>
                <img className={classes('pure-img', styles.image)} src={LandingAnimation} alt="Landing Image" />
            </div>
        </div>
        { 
            modalOpen && ( 
                <Modal email={email} visible={modalOpen} onClose={() => setModalOpen(false)}>
                    <RegisterForm email={email} />
                </Modal>
            )
        }
    </div>
  );
}
export default Home;
