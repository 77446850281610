import React, { useState, useRef } from 'react'
import * as styles from '@scss/registerForm.mod';
import { Formik, Form, Field } from 'formik';
import * as Yup from 'yup';
import CheckCircle from '@material-ui/icons/CheckCircle';
import ErrorIcon from '@material-ui/icons/Error';
import OpenInNewIcon from '@material-ui/icons/OpenInNew';
import { users_path } from 'routes';
import ReCAPTCHA from 'react-google-recaptcha';
import axios from 'axios';
import { classes } from '@root/helpers';

const RegisterForm = ({ initRecaptcha, email }) => {
  const recaptchaRef = useRef(null);

  const [ done, setDone ] = useState(false);
  const [ failed, setFailed ] = useState(false);
  const [ formErrors, setFormErrors ] = useState([]);
  const [ loading, setLoading ] = useState(false);

  const SignupSchema = Yup.object().shape({
    email: Yup.string()
      .min(2, 'Too Short!')
      .max(50, 'Too Long!')
      .required('Required'),
    market: Yup.string()
      .min(2, 'Too Short!')
      .max(50, 'Too Long!')
      .required('Required'),
    email: Yup.string().email('Invalid email').required('Required'),
  });

  const handleSubmit = (formData) => {
    const handleError = (error = null) => {
      setDone(false);
      setFailed(true);
    }

    const sendRequest = async () => {
      setLoading(true);
      recaptchaRef?.current?.reset();
      const _captcha = await recaptchaRef.current.executeAsync();
      if (!_captcha) return;

      axios.post('/users.json', {
        ...formData, 
        recaptcha: _captcha
      })
        .then((response) => {
          const resp = response?.data?.status;
          if (!resp) return handleError();
          if (resp === "save_failed") return handleError();
          if (resp === "captcha_failed") return setTimeout(sendRequest, 1000);

          setDone(true);
          setFailed(false);
          setTimeout(() => {
            setDone(false);
          }, 3500);
      }).catch(handleError)
        .finally(() => setLoading(false));
    };

    recaptchaRef?.current?.execute().then(sendRequest);
  };

  return (
    <div className={styles.registerForm}>
      <div className={styles.registerWrapper}>
        <h3 className={styles.title}>
          Request Access 
          { done && <CheckCircle className={styles.doneIcon}/> }
          { failed && <ErrorIcon className={styles.errorIcon} /> }
        </h3>
        <p className={styles.subtitle}>
          Fill in and submit your information below. You will receive a confirmation for your account shortly
        </p>
        <Formik
          initialValues={{
            email: email || '',
            market: 'de'
          }}
          validationSchema={SignupSchema}
          onSubmit={handleSubmit}
        >
          {({ errors, touched, values }) => (
            <Form className={styles.formWrapper}>
              <label className={styles.field} htmlFor="email">
                <Field className={values.email ? styles.nonempty : ''} name="email" type="email" />
                <span className={styles.placeholder}>Enter your Email</span>
                {errors.email && touched.email ? <span className={styles.errors}>{errors.email}</span> : null}
              </label>
              <label className={styles.field} htmlFor="market">
                <Field className={styles.nonempty} as="select" name="market" type="text">
                  {
                    [
                      ["Belgium(NL)", "be"], ["Belgium(FR)", "befr"],
                      ["Canada", "ca"], ["Germany", "de"],
                      ["Denmark", "dk"], ["Spain", "es"], 
                      ["Finland", "fi"], ["France", "fr"], 
                      ["Italy", "it"], ["Netherlands", "nl"],
                      ["Norway", "no"], ["Poland", "pl"],
                      ["Sweden", "se"], ["United Kingdom", "uk"],
                      ["Europe", "eu"]
                    ].map((_market) => (<option key={_market[0]} name="_market" value={_market[1]}>{_market[0]}</option>))
                  }
                </Field>
                <span className={styles.placeholder}>Market</span>
                {errors.market && touched.market ? <span className={styles.errors}>{errors.market}</span> : null}
              </label>
              <ReCAPTCHA
                size="invisible"
                ref={recaptchaRef}
                sitekey={RECAPTCHA_SITE_KEY}
                className={styles.recaptcha}
              />
              {
                done &&
                <span className={styles.response}>Access request accepted.</span>
              }
              {
                failed &&
                <span className={styles.response}>User is already registered or registration is not allowed.</span>
              }
              <button 
                type="submit" 
                className={classes("button-primary")} 
              >
                { loading ? <div className={styles.dotFlashing} /> : "Submit" }
              </button>
            </Form>
          )}
        </Formik>
      </div>
    </div>
  );
}

export default RegisterForm;
