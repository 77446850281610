import React from 'react';
import { useMediaQuery } from 'react-responsive';
import { new_activity_path } from 'routes';
import * as styles from '@scss/templateExample.mod';
import TemplatePC from '@images/templatePc.svg';
import Close from '@images/close-button.svg';
import { getTemplateName, classes } from '@root/helpers';

const TemplateExample = ({ details, current_user, templateOpen, setTemplateOpen, setOverlay }) => {
    const isTabletOrMobile = useMediaQuery({ maxWidth: 1023 });
    const isOpen = isTabletOrMobile && templateOpen;
    const available = current_user.role !== "user" || details.availableMarkets.includes(current_user.market);

    return (
        <div className={classes(styles.exampleWrapper, isOpen && styles.displaying)}>
            <div className={styles.exampleContainer}>
                <div className={styles.header}>
                    <h6>Template Preview</h6>
                    {
                        isTabletOrMobile && 
                            <img src={Close} className={classes('pure-img', styles.tempImg)} alt="TemplatePC" onClick={() => setTemplateOpen(false)}/>
                    }
                </div>
                <div className={styles.innerExample}>
                    <div className={styles.imageWrapper}>
                        <img src={TemplatePC} className={classes('pure-img', styles.tempImg)} alt="TemplatePC" />
                        {   
                            details.scr && 
                            <img src={details.scr} className={classes('pure-img', styles.tempScr)} alt="Scr" />
                        }
                    </div>
                    <h6>{getTemplateName(details.name)}</h6>
                    {   
                        details.scr && 
                        <a className={classes('button-secondary', styles.tmpBtn)} onClick={() => setOverlay(true)}>See Example</a>
                    }
                    <div className={styles.description}>
                        <h6>Description</h6>
                        <p className={styles.desc}> 
                            {details.description}
                        </p>
                    </div>
                    <div className={styles.markets}> 
                        <h6>Available in</h6>
                        <div className={styles.marketList}>
                            {
                                details.availableMarkets.map((market) => (
                                    <span key={market} className={styles.market}>{market}</span>
                                ))
                            }
                        </div>
                    </div>
                    <div className={styles.stats}>
                        <p className={styles.name}>Control</p>
                        <p className={styles.percentage}>10%</p>
                    </div>
                    <div className={styles.stats}>
                        <p className={styles.name}>Variation</p>
                        <p className={styles.percentage}>90%</p>
                    </div>
                    <a className={classes('button-primary', styles.tmpBtn, styles.use, !available && styles.disable)} href={new_activity_path({ template: details.name })}>{available ? 'Use this template' : 'Not available'}</a>
                    <a className={classes('button-ternary', styles.tmpBtn)} href={`mailto:info@testful.co.uk?subject=Template ${details.name}`}>Contact developer &#129125;</a>
                </div>
            </div>
        </div>
    );
}

export default TemplateExample;
