const schema = {
    "$schema": "http://json-schema.org/draft-04/schema#",
    "type": "object",
    "properties": {
        "url": {
            "type": "string",
            "default": "https://"
        },
        "editable": {
            "title": "Editable",
            "type": "boolean",
            "default": false
        },
    },
    "required": [
        "id",
        "name",
    ]
};

const uiSchema = {
    "type": "VerticalLayout",
    "elements": [
        {
            "type": "HorizontalLayout",
            "elements": 
            [
                {
                    "type": "Control",
                    "label": "Preview URL",
                    "scope": "#/properties/url"
                }
            ]
        },
        {
            "type": "Control",
            "scope": "#/properties/editable"
        }
    ]
};

export {
    schema,
    uiSchema
};
