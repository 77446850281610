import React, { useEffect } from 'react';
import { useMediaQuery } from 'react-responsive';
import Bowser from "bowser";
import * as styles from '@scss/noticeBanner.mod';
import NoticeDesktop from '@images/noticeDesktop.svg';
import NoticeMobile from '@images/noticeMobile.svg';
import Chrome from '@images/chrome.png';
import Close from '@images/close-button.svg';
import { sendExtMessage, extVersion } from '@root/helpers';

const NoticeBanner = ({ onClose, setOpen }) => {
    const browser = Bowser.getParser(window.navigator.userAgent);
    const isDesktopOrLaptop = useMediaQuery({ minWidth: 1024 });
    const isChrome = browser.satisfies({ chrome: ">20" });
    const runtime = !!window?.chrome?.runtime;

    useEffect(() => {
        const openNotice = () => {
            if (sessionStorage.getItem("testfulNotice")) return;

            const intervalId = setTimeout(() => setOpen(true), 4000);
            return () => clearTimeout(intervalId);
        };

        if(!isDesktopOrLaptop || !isChrome || !runtime) return openNotice();

        sendExtMessage(
            { greeting: "TestfulVersion" },
            (response) => {
                const version = response?.farewell;
                const installRequired = chrome.runtime.lastError || !version || version !== extVersion;
                if (installRequired) openNotice();
            }
        );
    }, []);

    const getExtension = () => {
        window.open('https://chrome.google.com/webstore/detail/testful/fmjomgnolbhnkpgkiefndeggkppflbeg', '')
    };

    const openInChrome = () => {
        console.log("Open in Chrome");
    };

    return (
        <div className={styles.noticeBanner}>
            <div className={styles.noticeBannerWrapper}>
                <div className={styles.bannerImages}>
                    <img className={`pure-img ${styles.close}`} src={Close} alt="Landing Image" onClick={onClose}/>
                    <img className={`pure-img`} src={isDesktopOrLaptop ? NoticeDesktop : NoticeMobile} alt="Landing Image" />
                    {
                        isDesktopOrLaptop && <img className={`pure-img ${styles.chrome}`} src={Chrome} alt="Landing Image" />
                    }
                </div>
                <div className={styles.bannerTxt}>
                    <h3 className={styles.title}>
                        Before you continue...
                    </h3>
                    <p className={styles.desc}>
                    {
                        isDesktopOrLaptop ? 
                            isChrome ? 
                                'Please install the most recent version of our Chrome extension in order to preview and deploy activities.' : 
                                'Use Chrome Browser in order to use the full capabilities. ' 
                            : 'Use a desktop device in order to use the full capabilities.'
                    }
                    </p>
                    <button className="button-primary" onClick={isDesktopOrLaptop ? isChrome ? getExtension : openInChrome : onClose}>
                    {
                        isDesktopOrLaptop && isChrome ?
                            'Get it here' :
                                !isChrome ?
                                    'Open in Chrome' :
                                    'Contiue using on mobile'
                    }
                    </button>
                </div>
        </div>
        </div>
    );
}

export default NoticeBanner;