import React, { useState, useEffect } from 'react';
import { useMediaQuery } from 'react-responsive';
import * as styles from '@scss/toolPlayground.mod';
import JsonForms from '@components/JsonForms';
import Trademark from '@components/Trademark';
import CheckCircle from '@material-ui/icons/CheckCircle';
import ErrorIcon from '@material-ui/icons/Error';
import { classes } from '@root/helpers';
import { defaultSchema, defaultUiSchema } from '@schemas/Tool/JSONForm';

import AceEditor from 'react-ace';
import 'ace-builds/webpack-resolver';
import 'ace-builds/src-noconflict/mode-json';
import 'ace-builds/src-noconflict/theme-github';
import 'ace-builds/src-noconflict/keybinding-emacs';
import 'ace-builds/src-noconflict/keybinding-vim';
import { createAjv } from "@jsonforms/core";

const FormPlayground = () => {
    const [changeStatus, setChangeStatus] = useState(null);
    const isTabletOrMobile = useMediaQuery({ maxWidth: 1023 });
    const initialJSONPayload = {
        schema: defaultSchema,
        uiSchema: defaultUiSchema,
    };

    const [JSONPayload, setJSONPayload] = useState(initialJSONPayload);
    const [data, setData] = useState({});

    let timeout = null;

    useEffect(() => {
        if (timeout !== null) return;

        timeout = setTimeout(() => {
            console.log(data);
            clearTimeout(timeout);
            timeout = null;
        }, 1000);
    }, [data]);

    const onChange = (newValue) => {
        setChangeStatus('loading');
        try {
            const Ajv = createAjv({ useDefaults: true });
            const newJSONPayload = JSON.parse(newValue, null, 2);
            const validSchema = Ajv.validateSchema(newJSONPayload?.schema);
            if (validSchema) setJSONPayload(newJSONPayload);
            else throw Error;
            setChangeStatus('done');

            setTimeout(() => setChangeStatus(null), 1000);
        } catch (err) {
            console.log("Invalid form. Try-Catch");
            setChangeStatus('failed');
        }
    };

    return (
        <div className={styles.toolWrapper}>
            <div className={styles.toolContainer}>
                <div className={classes(styles.header, styles.marginBottom)}>
                    <h3>JSON Form Playground</h3>
                    {changeStatus == "done" && <CheckCircle className={styles.doneIcon} />}
                    {changeStatus == "failed" && <ErrorIcon className={styles.errorIcon} />}
                </div>
                <div className={styles.toolInner}>
                    <AceEditor
                        mode="json"
                        theme="github"
                        name="json-editor"
                        showPrintMargin={true}
                        showGutter={true}
                        highlightActiveLine={true}
                        defaultValue={JSON.stringify(JSONPayload, null, 2)}
                        enableBasicAutocompletion={false}
                        enableLiveAutocompletion={false}
                        enableSnippets={false}
                        setOptions={{
                            showLineNumbers: true,
                            tabSize: 2
                        }}
                        editorProps={{ $blockScrolling: true }}
                        onChange={onChange}
                        keyboardHandler='emacs'
                        debounceChangePeriod={1000}
                    />
                    <div className={styles.formWrapper}>
                        <JsonForms
                            schema={JSONPayload?.schema}
                            uischema={JSONPayload?.uiSchema}
                            data={data}
                            onChange={({ data, _errors }) => setData(data)}
                        />
                    </div>
                </div>
                <Trademark />
            </div>
            {isTabletOrMobile && <div className={styles.mobilePlacement} />}
        </div>
    );
}

export default FormPlayground;