import React, { useState, useEffect } from 'react'
import * as styles from './Checkbox.scss'

const Checkbox = (props) => {
    const { id, label, data, updateValue } = props;
    const [ checkboxState, setCheckboxState ] = useState(!!data);

    const changeValue = () => {
        const newState = !checkboxState;    
        updateValue(newState);
        setCheckboxState(newState);
    }

    return (
        <div className='jsonCheckbox' onClick={()=> changeValue()}>
            <label className='switch' htmlFor="checkbox">
                <input type='checkbox' id={id} checked={checkboxState} onChange={()=> {}} />
                <div className='slider round'></div>
            </label>
            <span>{ label }</span>
        </div>
    );
}

export default Checkbox
