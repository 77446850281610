import React from 'react';
import Banner from '@components/Banner';
import left from '@images/HowItWorksLeft.svg';
import right from '@images/HowItWorksRight.svg';
import styles from '@scss/howItWorks.mod';
import create from '@images/newPicture.svg';
import template from '@images/template.svg';
import pippete from '@images/pipette.svg';
import check from '@images/check.svg';
import footerPic from '@images/magnifier.png';
import { faq_path } from 'routes';
import { classes } from '@root/helpers';

const Tile = ({ number, image, heading, paragraph }) => (
    <div className={classes("pure-u-1 pure-u-md-1-2 pure-u-xl-1-4", styles.contentWrapper)}>
        <div className={styles.number}>
            <h1>{number}</h1>
            <div className={styles.rectangle} data-number={number}>
                <img src={image} alt="title" />
            </div>
        </div>
        <div className={styles.text}>
            <h3>{heading}</h3>
            <p>{paragraph}</p>
        </div>
   </div>
);

const HowItWorksContent = () => (
    <React.Fragment>
        <div className={classes("pure-g", styles.content)}>
            <Tile 
                number={1}
                image={create}
                heading='Create a new Activity' 
                paragraph=''
            />
            <Tile 
                number={2}
                image={template}
                heading='Choose a Template' 
                paragraph=''
            />
            <Tile 
                number={3}
                image={pippete}
                heading='Customize the activity' 
                paragraph=''
            />
            <Tile 
                number={4}
                image={check}
                heading='Build & Deploy' 
                paragraph=''
            />
        </div>
        <div className={styles.footerWrapper}>
            <div className={styles.footer}>
                <img src={footerPic} alt="" />
                <h1>
                  Need more information on how the platform works or you have more
                  questions?
                </h1>
                <a href={faq_path()}>
                    <button className={`button-primary ${styles.button}`}>SEE OUR FAQs</button>
                </a>
            </div>
        </div>
    </React.Fragment>
);

const HowItWorks = () => (
    <div className={styles.container}>
        <Banner 
            leftImg={left} 
            heading="How it works" 
            paragraph='Creating and deploying A/B activities has never been easier. Testful lets you preview of built activities without the need of Adobe Target.'
            rightImg={right}
        />
        <HowItWorksContent />
    </div>

);

export default HowItWorks;