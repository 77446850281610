import React, { useState } from 'react'
import styles from '@scss/stepsForm.mod.scss';
import { Button, GridListTile, GridListTileBar, IconButton } from '@material-ui/core';
import { classes } from '@root/helpers';

const StepLines = ({ step, steps }) => {
    const stepClass = (limit) => step >= limit ? styles.green : styles.default;
    const stepLines = () => Array.from({ length: steps }).map((x, i) => (
        <div key={i} className={stepClass(i+1)}></div>
    ));

    return (
        <div className={styles.horizontalBreaks}>
            { stepLines() }
        </div>
    );
};

const Steps = ({ 
    components, titles, errors,
    buttonTitles, handleSubmit,
    hint, onStep
}) => {
    const lastStep = components?.length;
    const paramStep = onStep && parseInt(onStep);
    const currentStep = paramStep && paramStep <= lastStep && paramStep > 0 ? paramStep : 1;
    const [ step, setStep ] = useState(currentStep);
    const stepIndex = step - 1;

    const scrollEffect = () => {
        window.scrollTo({
          top: 100,
          left: 100,
          behavior: 'smooth'
        });
    };

    const updateURL = (_step) => window.history.pushState(null, null, `?step=${_step}`);

    const goBack = () => {
        if (step === 1) return;

        setStep(step - 1);
        updateURL(step - 1);
        scrollEffect();
    };

    const nextOrSubmit = () => {
        if (errors.length !== 0) return;
        if (step !== lastStep) {
            updateURL(step + 1);
            return setStep(step + 1);
        }

        handleSubmit();
        scrollEffect();
    }

    return (
        <div className={styles.stepsForm}>
            <h6 className={styles.stepHeading}> Step { step }</h6>
            <h1 className={styles.template}>{ titles[stepIndex] }</h1>
            <StepLines step={step} steps={lastStep} />

            <div className={styles.form}>
                { components.map((component, index) => index === stepIndex && component) }
            </div>

            { hint && <span className={styles.hint}>{ hint }</span> }

            <div className={styles.containerSaveButton}>
                {step > 1 && <button className={classes("pure-button button-secondary", styles.backBtn)} onClick={goBack}>BACK</button> }
                <button className="pure-button button-primary" variant="contained" color="primary" onClick={nextOrSubmit}>{ buttonTitles[stepIndex] }</button>
            </div>
        </div>
    )
};

export default Steps
