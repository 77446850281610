import React, { useEffect, useRef } from 'react';
import * as styles from '@scss/modal.mod';

const Modal = ({ visible, onClose, children }) => {
  const _modal = useRef(null);

  useEffect(() => {
    if (visible) {
      _modal.current.classList.add(styles.displaying); 
      setTimeout(() => _modal.current?.classList.add(styles.visible), 200);
    } else {
      _modal.current.classList.remove(styles.visible);
      setTimeout(() => _modal.current?.classList.remove(styles.displaying), 200);
    }
  });

  return (
    <div ref={_modal} className={styles.modal}>
      <div onClick={onClose} className={styles.overlay} />
      <div className={styles.body}>
        { children }
      </div>
    </div>
  );
};

export default Modal;
