import React, { useState, useEffect } from "react"
import { GridList, GridListTile, GridListTileBar, IconButton } from '@material-ui/core';
import OpenInNewIcon from '@material-ui/icons/OpenInNew';
import { activities_path, audiences_path } from 'routes';
import { withOurTheme } from '../theme';
import axios from 'axios';
import ActivityNotifications from "@components/Activity/ActivityNotifications";
import JsonForms from "@components/JsonForms";
import StepsForm from "@components/StepsForm";
import SearchDropdown from "@components/SearchDropdown";
import { createAjv } from "@jsonforms/core";
import styles from "@scss/activityForm.mod.scss";
import { 
  selectedMarket,
  restrictAudConfigsSources,
} from '@root/helpers';

const ActivityForm = (props) => {
  const { 
    templates, activity, onStep,
    targetConfigSchema, current_user } = props;

  if (current_user.role === "user") {
    targetConfigSchema[0].properties.market.enum = targetConfigSchema[0].properties.market.enum.filter((market) => market.includes(current_user.market));
    targetConfigSchema[1].properties.market.enum = targetConfigSchema[1].properties.market.enum.filter((market) => market.includes(current_user.market));
  }

  const [ formErrors, setFormErrors ] = useState([]);
  const [ formData, setFormData ] = useState(activity || {name: '[TF] ', template: '', activity_type: '', note: ''});
  const [ template, setTemplate ] = useState(null);
  const [ config, setConfig ] = useState(activity?.config || {});
  const [ activityConfig, setActivityConfig ] = useState(activity?.activity_config || {});
  const [ hint, setHint ] = useState(null);
  const [ scriptSnippet, setScriptSnippet ] = useState(activity?.script_snippet);
  const [ styleSnippet, setStyleSnippet ] = useState(activity?.style_snippet);

  useEffect(() => {
    const _template = templates.find((_template) => _template.name === formData.template);
    setTemplate(_template);
  }, [formData]);

  useEffect(() => {
    const defaultTemplate = new URLSearchParams(window.location.search).get('template');
    if (defaultTemplate) setFormData({ name: '[TF] ', template: defaultTemplate, activity_type: '', note: '' });
  }, []);

  const defaultSchema = { 
    "type": "object",
    "properties": {
      "name": {
        "type": "string",
        "title": "Name the activity"
      },
      "template": {
        "title": "Template",
        "type": "string",
        "enum": templates.map((template) => template.name)
      },
      "activity_type": {
        "title": "Activity Type",
        "type": "string",
        "enum": ["A-B", "Experience-Targeting"]
      },
      "note": {
        "type": "string",
        "title": "Note",
      }
    },
    "required": [
      "name",
      "template",
      "activity_type"
    ]
  };

  const renderErrors = (errors) => Object.keys(errors).map((_key, i) => (<span key={i}>{_key} {errors[_key]}</span>));

  const handleSubmit = () => {
    const processErrors = (error) => setHint(error?.response?.data?.errors);
    const processResponse = (response) => {
      const { data } = response;
      const hasErrors = data.errors && Object.keys(data.errors).length !== 0;
      if (hasErrors) setHint(renderErrors(data.errors));
      else window.location.href = activities_path({ market: selectedMarket() });
    };

    if (formErrors.length === 0) { 
      if (activity && activity.id) { 
        const updateMarket = (current_user.role !== 'user') ? activityConfig.market : current_user.market;
        axios.put(`/activities/${activity.id}.json`,  {
          ...formData, config, 
          activity_config: activityConfig, 
          market: updateMarket, 
          script_snippet: scriptSnippet, 
          style_snippet: styleSnippet
        })
          .then(processResponse)
          .catch(processErrors);
      } else {
        axios.post('/activities.json', {
          ...formData, config, 
          activity_config: activityConfig, 
          market: activityConfig.market, 
          script_snippet: scriptSnippet, 
          style_snippet: styleSnippet
        })
          .then(processResponse)
          .catch(processErrors);
      }
    }
  };

  const onSelect = (selection) => {
    const selectedConfig = selection.config;
    const currentAudiences = activityConfig?.audiences;
    const newAudiences = currentAudiences.push(selectedConfig);
    const newConfig = { ...activityConfig, ...newAudiences};
    setActivityConfig(newConfig);
  };

  const handleDefaultsAjv = createAjv({ useDefaults: true });
  const templateScreenshots = template?.screenshots && template.screenshots.length !== 0;
  
  const Screenshots = () => (
    <div>
      <GridList className={styles.gridList}>
        {
          template?.screenshots?.map((screenshot, index) => (
            <GridListTile key={`screenshot-${index}`} className={styles.gridTile}>
              <img src={screenshot} alt={`screenshot-${index}`} className={styles.gridImage} />;
              <GridListTileBar
                title={`Screenshot ${index + 1}`}
                actionIcon={
                  <IconButton
                    aria-label={`Screenshot ${index + 1}`}
                    className={styles.gridIcon}
                    onClick={() => window.open(screenshot)}
                  >
                    <OpenInNewIcon />
                  </IconButton>
                }
              />
            </GridListTile>
          ))
        }
      </GridList>
    </div>
  );

  const SelectTemplateForm = (
    <div key={1}>
      <JsonForms
        schema={defaultSchema}
        data={formData}
        onChange={({ data, errors }) => {
          const template = templates.find((template) => template.name === data.template);

          if (template && Object.keys(activityConfig).length === 0) {
            setActivityConfig(template.activity_setup);
          }

          setFormData(data);
          setFormErrors(errors);

        }}
        readonly={(activity?.remote_id !== null && activity?.remote_id !== undefined)}
      />
      {templateScreenshots && <Screenshots />}
    </div>
  );

  const ConfigureTemplateForm = (
    <div key={2}>
      <JsonForms
        schema={template?.schema}
        data={config}
        uischema={template?.ui_schema}
        onChange={({ data, errors }) => {
          setConfig(data)
          setFormErrors(errors);
        }}
        ajv={handleDefaultsAjv}
      />
      <textarea className={styles.textArea} value={scriptSnippet} onChange={(e) => setScriptSnippet(e.target.value)} placeholder="Script Snippet" />
      <textarea className={styles.textArea} value={styleSnippet} onChange={(e) => setStyleSnippet(e.target.value)} placeholder="Style Snippet" />
    </div>
  );

  const AudienceForm = (
    <div key={3}>
      <SearchDropdown 
        url={audiences_path}
        onSelect={onSelect}
        name="audience-preselect"
        label="Preselect Audience"
      />
      <JsonForms
        schema={restrictAudConfigsSources(formData.activity_type, template, targetConfigSchema, config)}
        data={activityConfig}
        onChange={({ data, errors }) => {
          if (activity?.remote_id !== null && activity?.remote_id !== undefined) data.market = activity?.market;
          setActivityConfig(data);
          setFormErrors(errors);
        }}
        ajv={handleDefaultsAjv}
      />
    </div>
  );

  return (
    <div className={styles.activityForm}>  
      <div className={styles.stepsFormWrapper}>
        <StepsForm
          components={[
            SelectTemplateForm,
            ConfigureTemplateForm,
            AudienceForm,
          ]}

          titles={[
            'Choose A Template',
            'Configure the activity',
            'Setup the activity',
          ]}

          buttonTitles={[
            'Continue',
            'Continue',
            'Save'
          ]}

          hint={hint}
          onStep={onStep}

          handleSubmit={handleSubmit}
          errors={formErrors}
        />
      </div>

      <ActivityNotifications />    
    </div>
  );
}
export default withOurTheme(ActivityForm)
