import React, { useState, useEffect, useCallback } from 'react';
import { useMediaQuery } from 'react-responsive';
import * as styles from '@scss/audienceList.mod';
import Trademark from '@components/Trademark';
import Plus from '@images/plus.svg';
import AudienceBox from '@components/Audience/AudienceBox';
import Modal from '@components/Modal';
import ModalForm from '@components/ModalForm';
import Sort from '@components/Sieve/Sort';
import Search from '@components/Sieve/Search';

import { schema, uiSchema } from '@schemas/Audience';
import axios from 'axios';
import { audience_path, search_audiences_path } from 'routes';
import { classes } from '@root/helpers';

const AudienceList = (props) => {
    const [audiences, setAudiences] = useState(props.audiences);
    const [audience, setAudience] = useState(null);
    const [modalOpen, setModalOpen] = useState(false);
    const isTabletOrMobile = useMediaQuery({ maxWidth: 1023 });

    const [sortAsc, setSortAsc] = useState(true);
    const [searchData, setSearchData] = useState(null);

    const openForm = (_audience) => {
        setAudience(_audience);
        setModalOpen(true);
    };

    const renderErrors = (errors) => Object.keys(errors).map((_key, i) => (<span key={i}>{_key} {errors[_key]}</span>));
    const processErrors = (error) => console.warn(error);
    const processResponse = (response) => {
        const { data } = response;
        const hasErrors = data.errors && Object.keys(data.errors).length !== 0;
        if (hasErrors) console.warn(renderErrors(data.errors));
        else window.location.reload();
    };

    const handleSubmit = (exists, data, errors) => {
        if (errors.length !== 0) return;

        axios({
            method: exists ? 'PUT' : 'POST',
            url: exists ? audience_path(audience.id) : '/audiences.js',
            data: { config: data }
        }).then(processResponse)
            .catch(processErrors);
    };

    const handleDelete = (data, errors) => {
        const confirmed = confirm("Are you sure?");
        if (!confirmed) return;

        axios({
            method: 'DELETE',
            url: audience_path(audience.id)
        }).then(processResponse)
            .catch(processErrors);
    };

    const form = (exists) => (
        <ModalForm
            schema={schema}
            uiSchema={uiSchema}
            exists={exists}
            data={audience?.config}
            name={'audience'}
            name='audience'
            handleSubmit={handleSubmit}
            handleDelete={handleDelete}
        />
    );

    const fetchAudiences = useCallback((name, sort) => {
        axios.get(search_audiences_path({ name, sort })).then((response) => {
            setAudiences(response.data.audiences);
        });
    }, [])

    const handleSearch = useCallback((e) => {
        setSearchData(e.target.value)
    }, []);

    useEffect(() => {
        clearTimeout(window.__searchTimeout);
        window.__searchTimeout = setTimeout(() => {
            fetchAudiences(searchData, sortAsc ? 'asc' : 'desc');
        }, 500);
    }, [searchData, sortAsc]);

    return (
        <div className={styles.audienceWrapper}>
            <div className={styles.audienceContainer}>
                <div className={styles.header}>
                    <h3>Audiences</h3>
                    <Search setSearchData={handleSearch} />
                </div>
                <div className={styles.sieve}>
                    <Sort
                        sortAsc={sortAsc}
                        setSortAsc={setSortAsc}
                        options={['Newest first', 'Oldest first']}
                    />
                    <a className={classes('pure-button', 'button-primary', styles.btn)} onClick={() => openForm(null)}>
                        <img src={Plus} className={classes('pure-img', styles.plus)} alt="Plus" />
                        New Audience
                    </a>
                </div>
                <div className={styles.audience}>
                    {
                        audiences?.map((_audience, index) => (
                            <AudienceBox
                                key={index}
                                audience={_audience}
                                openForm={() => openForm(_audience)}
                            />
                        ))
                    }
                </div>
                <Trademark />
            </div>

            {isTabletOrMobile && <div className={styles.mobilePlacement} />}

            <Modal
                visible={modalOpen}
                onClose={() => {
                    setModalOpen(false);
                    setAudience(null);
                }}
            >
                {audience && form(true)}
                {!audience && form(false)}
            </Modal>
        </div>
    );
}

export default AudienceList;
