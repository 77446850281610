import React from 'react';
import { Button } from '@material-ui/core';
import { Close } from '@material-ui/icons';
import { SnackbarProvider, useSnackbar } from 'notistack';
import { notifications, success, error, warning, info } from '@scss/notifications.mod';

const Notification = ({ key, message }) => (
    <div id={key}>
        <p>{message}</p>
        <Button onClick={onClickDismiss(key)}>
            <Close />
        </Button>
    </div>
);

const Notifications = React.forwardRef((props, ref) => {
    const onClickDismiss = key => () => ref.current.closeSnackbar(key);

    return (
        <SnackbarProvider
            anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'right',
            }}
            classes={{
                variantSuccess: `${notifications} ${success}`,
                variantError: `${notifications} ${error}`,
                variantWarning: `${notifications} ${warning}`,
                variantInfo: `${notifications} ${info}`,
            }}
            action={(key) => (
                <Button onClick={onClickDismiss(key)}>
                    <Close />
                </Button>
            )}
            ref={ref}
        />
    );
});

export default Notifications;
