import React, { useState, useRef } from "react"
import { 
  user_google_oauth2_omniauth_authorize_path, 
  user_azure_activedirectory_v2_omniauth_authorize_path, 
  faq_path
} from 'routes';
import * as styles from '@scss/loginForm.mod';
import LoginImage from '@images/login.png';
import Logo from '@images/logo.png';
import SogodyLogo from '@images/sogodyLogo.png';
import GoogleIcon from '@images/googleIcon.svg';
import MicrosoftIcon from '@images/microsoftIcon.svg';
import Notifications from '@components/Notifications';
import Loader from '@components/Loader';
import { classes } from '@root/helpers';

const OAuthLogin = ({ action, target, csrf, OAuthButton, icon, title, callback }) => {
  const _form = useRef(null);
  const [ loading, setLoading ] = useState(false);

  const openWindow = ({url, title, w, h}) => {
    // Fixes dual-screen position                             Most browsers      Firefox
    const dualScreenLeft = window.screenLeft !==  undefined ? window.screenLeft : window.screenX;
    const dualScreenTop = window.screenTop !==  undefined   ? window.screenTop  : window.screenY;

    const width = window.innerWidth ? window.innerWidth : document.documentElement.clientWidth ? document.documentElement.clientWidth : screen.width;
    const height = window.innerHeight ? window.innerHeight : document.documentElement.clientHeight ? document.documentElement.clientHeight : screen.height;

    const systemZoom = width / window.screen.availWidth;
    const left = (width - w) / 2 / systemZoom + dualScreenLeft
    const top = (height - h) / 2 / systemZoom + dualScreenTop
    const newWindow = window.open(title, url, 
      `
      scrollbars=yes,
      width=${w / systemZoom}, 
      height=${h / systemZoom}, 
      top=${top}, 
      left=${left}
      `
    );

    return newWindow;
  };

  const newURL = (_url) => {
    try {
      const url = new URL(_url);
      return url;
    } catch (_) {
      return null;  
    }
  };

  const handleSubmit = () => {
    setLoading(true);
    const newWindow = openWindow({url: target, title: "Login", w: 450, h: 550}); 
    _form.current.submit();
    const interval = setInterval(function () 
    {
      try {
        const url = newWindow?.location;
        const params = url && newURL(url)?.searchParams;
        const loggedIn = params && params.get("logged_in");
        if (loggedIn === "true") 
        {
          clearInterval(interval);
          newWindow.close();
          callback({ data: "Logged In", variant: "success" });
          setTimeout(() => location.reload(), 1000);
        } 
        else if (loggedIn === "error") {
          clearInterval(interval);
          newWindow.close();
          callback({ data: "Login failed!", variant: "error" });
        } 
        else if (newWindow.closed) {
          clearInterval(interval);
          callback({ data: "Login unsuccessful!", variant: "error" });
          setTimeout(() => {
            location.reload();
            setLoading(false);
          }, 500);
        }
      } catch (error) {}
    }, 250);
  }


  return (
    <>
      <form ref={_form} method="post" action={action} target={target} className={styles.oAuthLogin}>
        <input type="hidden" name="authenticity_token" value={csrf} />
          <button
            type="submit" 
            onClick={handleSubmit}
          >
            <img src={icon} alt={title} />
            <span>{title}</span>
          </button>
      </form>
      <Loader active={loading} />
    </>
  );
};

const LoginForm = (props) => {
  const _notifications = React.createRef();
  const _googleForm = useRef(null);
  const _micrsoftForm = useRef(null);

  const enqueueMsg = (_msg) => {
    const onClickDismiss = key => () => _notifications.current.closeSnackbar(key);
    _notifications.current.enqueueSnackbar(_msg.data, { variant: _msg.variant });
  };

  return (
    <div className={classes('pure-g', styles.loginWrapper)}>
      <div className={classes('pure-g', styles.loginContainer)}>
        <div className="pure-u-1 pure-u-md-1-2">
          <img className={classes('pure-img', styles.image)} src={LoginImage} alt="Login Image" />
        </div>
        <div className="pure-u-1 pure-u-md-1-2">
          <div className={styles.rightWrapper}>
            <img className={classes('pure-img', styles.logo)} src={Logo} alt="Logo" />
            <span className={styles.subtitle}>Welcome back! Please login to your account.</span>
            <div className={styles.oAuthWrapper}>
              <OAuthLogin 
                action={user_google_oauth2_omniauth_authorize_path()} 
                target="newGoogleWindow"
                csrf={props.csrf_token}
                icon={GoogleIcon}
                title="Continue with Google"
                callback={enqueueMsg}
              />
              <OAuthLogin 
                action={user_azure_activedirectory_v2_omniauth_authorize_path()} 
                target="newMicrosoftWindow"
                csrf={props.csrf_token}
                icon={MicrosoftIcon}
                title="Continue with Microsoft"
                callback={enqueueMsg}
              />
            </div>
            <a href={faq_path()} className="button-ternary">TROUBLE LOGGING IN?</a>

            <img className={classes('pure-img', styles.sogodyLogo)} src={SogodyLogo} alt="Sogody Logo" />
          </div>
        </div>
      </div>
      <Notifications ref={_notifications} />
    </div>
  );
}
export default LoginForm;
