import React , { useState } from 'react';
import * as styles from '@scss/box.mod';
import TemplatePC from '@images/templatePc.svg';
import { getTemplateName, classes } from '@root/helpers';

const Box = (props) => {
    const [ content, _ ] = useState(props.content);

    const changeContent = (e) => {
        e.preventDefault();
        if (content.link && e.detail == 2) {
            window.location.href = content.link;
        }
        props.setContent(content.name);
        props.setDetails({ name: content.name, description: content?.schema?.description || content?.description, link: content.link,  scr: content && content?.screenshots?.length !== 0 && content.screenshots[0], availableMarkets: content.available_market});
        if(!props.isDesktopOrLaptop) props.setContentOpen(true);
    }

    return (
        <div className={classes(styles.box, (props.content === content.name) && styles.selectedBox)} onClick={changeContent}>
            <div className={styles.boxContainer}>
                <img src={TemplatePC} className={classes('pure-img', styles.smallImg)} alt="TemplatePC" />
                { content?.screenshots && content.screenshots.length !== 0 && 
                    <img src={content.screenshots[0]} className={classes('pure-img', styles.smallScr)} alt="Scr" />
                }
                <h6>{getTemplateName(content.name)}</h6>
                <p className={styles.id}>ID: {content.name}</p>
                <p className={styles.date}>
                    {
                        content.created_at && new Date(content.created_at).toLocaleString()
                    }
                </p>
            </div>
        </div>
    );
}

export default Box;