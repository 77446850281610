import React, { useState, useRef, useCallback } from 'react';
import { 
    activities_path,
    audiences_path, 
    templates_path, 
    tools_path,
    root_path, 
    destroy_user_session_path 
} from 'routes';
import * as styles from '@scss/sidebar.mod';
import Points from '@images/ellipsisw.svg';
import Clipboard from '@images/clipboard.svg';
import Customer from '@images/customer.svg';
import Browser from '@images/browser.svg';
import Settings from '@images/settings.svg';
import Cheil from '@images/cheil.svg';
import { getIitials, useOnClickOutside, classes } from '@root/helpers';

const SideBar = (props) => {
    const { user } = props;
    const active = (path) => location.pathname.match(path) && styles.active;
    const [ log, setLog ] = useState(false);
    const ref = useRef();
    useOnClickOutside(ref, useCallback(() => setLog(false), []));

    return (
        <div className={styles.side}>
            <div className={styles.placement} />
            <div className={classes('pure-menu', styles.sideWrapper)}>
                <div className={styles.sideContainer}>
                    <div className={styles.sideTop}>
                        <span className="pure-menu-heading">
                            <a href={root_path()}>
                                <img src={Cheil} className={classes('pure-img', styles.logo)} alt="logo" />
                            </a>
                        </span>
                        <ul className="pure-menu-list">
                            <li className="pure-menu-item">
                                <a href={activities_path()} className={classes('pure-menu-link', styles.sideLink, active(/^\/activities/))}>
                                    <img src={Clipboard} className="pure-img" alt="points" />
                                    <span>Activities</span>
                                </a>
                            </li>
                            <li className="pure-menu-item">
                                <a href={audiences_path()} className={classes('pure-menu-link', styles.sideLink, active(/^\/audiences/))}>
                                    <img src={Customer} className="pure-img" alt="points" />
                                    Audiences
                                </a>
                            </li>
                            <li className="pure-menu-item">
                                <a href={templates_path()} className={classes('pure-menu-link', styles.sideLink, active(/^\/templates/))}>
                                    <img src={Browser} className="pure-img" alt="points" />
                                    Templates
                                </a>
                            </li>
                            <li className="pure-menu-item">
                                <a href={tools_path()} className={classes('pure-menu-link', styles.sideLink, styles.settingsLink, active(/^\/tools/))}>
                                    <img src={Settings} className="pure-img" alt="points" />
                                    Tools
                                </a>
                            </li>
                        </ul>
                    </div>
                    <div className={styles.sideBottom}>
                        <img src={Points} className={classes('pure-img', styles.points)} alt="points"  onClick={() => setLog(true)}/>
                        <span  className={styles.user}>
                            {getIitials(user.full_name)}
                        </span>
                        <ul className={classes('pure-menu-list', !log && styles.hide)} ref={ref}>
                            <li className={classes('pure-menu-item', styles.sideBottomLink)}>
                                <a className={classes('pure-menu-link', styles.bottomLink)} href={destroy_user_session_path()}>
                                    Log out
                                </a>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default SideBar;