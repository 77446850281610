import React, { useState } from 'react'
import './Input.scss';

const Input = (props) => {
    const {
        id,
        data,
        updateValue,
        required,
        title,
        label,
        errors
    } = props;
    const [input, setInput] = useState('');

    const submitHandler = (e) => {
        updateValue(e.target.value);
        setInput(e.target.value);
    }
    return (
        <div className='jsonInputContainer' >
          <div className='inputGroup'>
              <input id={id} placeholder='Placeholder...' value={input} onChange={submitHandler} type='text' className='inputControl' />
              <label className='inputLabel' htmlFor={id}>{label ? label : 'No Description available'}</label>
          </div>
        </div>
    )
}

export default Input;
