import React, { useState } from 'react';
import { activities_path, root_path } from 'routes';
import * as styles from '@scss/mobileNavbar.mod';
import Logo from '@images/logo.png';
import MenuImg from '@images/menu.svg';
import Close from '@images/close-button.svg';
import Menu from '@components/Navigation/Menu';
import Modal from '@components/Modal';
import RegisterForm from '@components/RegisterForm';
import { classes } from '@root/helpers';

const MobileNavbar = (props) => {
    const { user } = props;
    const [menuOpen, setMenuOpen] = useState(false);
    const [modalOpen, setModalOpen] = useState(false);

    return (
        <div className={styles.nav}>
            <div className={classes('pure-menu', 'pure-menu-horizontal', styles.navWrapper)}>
                <div className={styles.navContainer}>
                        <a onClick={() => window.location.href = user ? activities_path() : root_path()}>
                            <img src={Logo} className={classes('pure-img', styles.logo)} alt="logo" />
                        </a>
                        <a onClick={() => setMenuOpen(!menuOpen)}>
                            <img src={menuOpen ? Close : MenuImg} className={classes('pure-img', styles.menu)} alt="logo" />
                        </a>
                </div>
            </div>
            <Menu visible={menuOpen} setModalOpen={setModalOpen} user={user}/>
            <Modal visible={modalOpen} onClose={() => setModalOpen(false)}>
                <RegisterForm />
            </Modal>
        </div>
    );
};

export default MobileNavbar;