import React from 'react';
import * as styles from '@scss/pagination.mod';
import { classes } from '@root/helpers';

const Pagination = (props) => {
    const { page, setPage, pages, status } = props;
    const defaultPages = props.pgDisplay ? props.pgDisplay : 3;
    const pageButtonCount = (pages >= defaultPages) ? defaultPages : pages

    return (
        <div className={styles.paginationWrapper}>
            <div className={styles.pagination}>
                <a className={classes(styles.changePage, (page === 1 || pages <= defaultPages) && styles.hidden)} onClick={() => !status && setPage(page - 1)}>
                    <span className={classes(styles.arrow, styles.left)} />
                </a>
                {
                    Array(pageButtonCount).fill(1).map((el, i) => (
                        (page > pages - (defaultPages - 1)) ?
                            (pages >= defaultPages) ?
                                <a className={classes(styles.page, page == pages + i + 1 - defaultPages && styles.active)}
                                    onClick={() => !status && setPage(pages + i + 1 - defaultPages)} key={(pages + i + 1 - defaultPages).toString()}
                                >
                                    {pages + i + 1 - defaultPages}
                                </a>
                                :
                                <a className={classes(styles.page, page == i + (defaultPages - pages) && styles.active)}
                                    onClick={() => !status && setPage(i + (defaultPages - pages))} key={(i + (defaultPages - pages)).toString()}
                                >
                                    {i + (defaultPages - pages)}
                                </a>
                            :
                            <a
                                className={classes(styles.page, page == page + i && styles.active)}
                                onClick={() => !status && setPage(page + i)} key={(page + i).toString()}
                            >
                                {page + i}
                            </a>
                    ))
                }
                <a className={classes(styles.changePage, page >= pages - (defaultPages - 1) && styles.hidden)} onClick={() => !status && setPage(page + 1)}>
                    <span className={classes(styles.arrow, styles.right)} />
                </a>
            </div>
        </div>
    )
}

export default Pagination;