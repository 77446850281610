import React, { useState, useRef, useCallback } from 'react';
import { 
    activities_path,
    audiences_path, 
    templates_path, 
} from 'routes';
import * as styles from '@scss/mobileBottomSidebar.mod';
import Clipboard from '@images/clipboard.svg';
import Customer from '@images/customer.svg';
import Browser from '@images/browser.svg';
import { classes, useOnClickOutside } from '@root/helpers';

const MobileBottomSidebar = () => {
    const active = (path) => location.pathname.match(path) && styles.active;
    const [ log, setLog ] = useState(false);
    const ref = useRef();
    useOnClickOutside(ref, useCallback(() => setLog(false), []));

    return (
        <div className={styles.bottomSide}>
            <div className={styles.bottomSideWrapper}>
                <div className={classes('pure-menu', 'pure-menu-horizontal', styles.bottomSideContainer)}>
                    <ul className={classes('pure-menu-list', styles.list)}>
                        <li className="pure-menu-item">
                            <a href={activities_path()} className={classes('pure-menu-link', styles.bottomSideLink, active(/^\/activities/))}>
                                <img src={Clipboard} className={`pure-img`} alt="points" />
                                <span>Activities</span>
                            </a>
                        </li>
                        <li className="pure-menu-item">
                            <a href={audiences_path()} className={classes('pure-menu-link', styles.bottomSideLink, active(/^\/audiences/))}>
                                <img src={Customer} className={`pure-img`} alt="points" />
                                <span>Audiences</span>
                            </a>
                        </li>
                        <li className="pure-menu-item">
                            <a href={templates_path()} className={classes('pure-menu-link', styles.bottomSideLink, active(/^\/templates/))}>
                                <img src={Browser} className={`pure-img`} alt="points" />
                                <span>Templates</span>
                            </a>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    );
}

export default MobileBottomSidebar;