import React, { useState, useEffect, useRef, useCallback } from 'react';
import * as styles from '@scss/sort.mod';
import Descendant from '@images/descendant.svg';
import Arrow from '@images/arrow.svg';
import { classes, useOnClickOutside } from '@root/helpers';

const Sort = (props) => {
    const [ dropdown, setDropdown ] = useState(false);
    const [ selectedOption, setSelectedOption ] = useState(props.options[0]);
    const [ sortOption, setSortOption ] = useState(props.options[0]);

    const ref = useRef();
    useOnClickOutside(ref, useCallback(() => setDropdown(false), []));

    useEffect(() => {
        if (props.sortAsc) {
            setSortOption(props.options[1]);
            setSelectedOption(props.options[0]);
        }
        else {
            setSortOption(props.options[0]);
            setSelectedOption(props.options[1]);
        }
    }, [props.sortAsc]);

    return (
        <div className={styles.sortContainer}>
            <div className={styles.sortBtn}>
                <div className={styles.sortBtnContent} onClick={() => setDropdown(true)}>
                    <img src={Descendant} className={classes('pure-img', styles.doubleArrow)} alt="Descendant" />
                    <p>Sorted by:</p>
                    <p className={styles.darker}>{selectedOption}</p>
                    <img src={Arrow} className={classes('pure-img', styles.arrow)} alt="Arrow" />
                </div>
                <ul className={classes('pure-menu-list', !dropdown && styles.hide)} ref={ref}>
                    <li className="pure-menu-item">
                        <a className={classes('pure-menu-link', styles.link)} onClick={() => props.setSortAsc(!props.sortAsc)}>
                            {sortOption}
                        </a>
                    </li>
                </ul>
            </div>
        </div>
    );
};

export default Sort;
